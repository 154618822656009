import Link from 'next/link';
import { useContextualRouting } from 'next-use-contextual-routing';
import { IconButton } from '@mui/material';

import Expand from 'components/Icons/Expand';
import { subregionForecastPath } from 'utils/urls';

import styles from './SubregionForecastLink.module.scss';

interface Props {
  spotId?: string;
  start?: string;
  subregionId: string;
  subregionName: string;
}

const SubregionForecastLink: React.FC<Props> = ({ spotId, start, subregionId, subregionName }) => {
  const { makeContextualHref } = useContextualRouting();

  const contextualHref = makeContextualHref({
    subregionForecast: subregionId,
    start,
  });
  const contextualHrefAs = subregionForecastPath({ subregionId, subregionName, spotId });

  return (
    <Link href={contextualHref} as={contextualHrefAs} shallow>
      <IconButton
        aria-label="Subregion Forecast"
        className={styles.expandButton}
        data-testid="subregion-forecast-link-button"
        disableFocusRipple
        disableRipple
        disableTouchRipple
      >
        <Expand />
      </IconButton>
    </Link>
  );
};

export default SubregionForecastLink;
