import { ForecastWaveHeight } from 'types/spot';
import type { Units } from 'types/units';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { cx } from '@emotion/css';
import { conditionClassModifier, conditionEnumToText } from '@surfline/web-common';
import formatWaveHeight from 'utils/formatWaveHeight';
import classNames from 'classnames';
import styles from './ConditionsBar.module.scss';

type Props = {
  surfHeight: ForecastWaveHeight;
  rating: string;
  units: Units;
};

const getConditionsClassName = (
  className: string,
  conditions: string,
  type: 'background' | 'color',
  alwaysDisplaySmall: boolean,
  isTooltipMode: boolean = false,
) =>
  cx({
    [className]: true,
    [styles.conditionsBlockSmall]: !!alwaysDisplaySmall && className !== 'conditionsText',
    [`sl-conditions-color--${conditionClassModifier(conditions)}-${type}`]: true,
    [styles.isTooltip]: isTooltipMode,
  });

const ConditionsBar = ({ surfHeight, rating, units }: Props) => {
  const isFlat = !surfHeight;
  const waveHeightVariant = 'title2';

  const unitsVariant = 'h4';

  const conditionsVariant = 'callout1';

  return (
    <div className={styles.conditionsContainer}>
      {units && (
        <Stack direction="row" className="current-wave-height-and-rating">
          {rating && (
            <Box
              className={classNames(
                styles.conditionsColorBar,
                getConditionsClassName('conditionsBlock', rating, 'background', true, false),
              )}
            />
          )}
          <Stack direction="row">
            <Stack direction="row" alignItems="baseline" className={styles.waveHeight}>
              {isFlat ? (
                <Typography
                  whiteSpace="nowrap"
                  variant={waveHeightVariant}
                  component="p"
                  color="text.brandSecondary"
                >
                  Flat
                </Typography>
              ) : (
                <Typography component="p">
                  <Typography
                    whiteSpace="nowrap"
                    variant={waveHeightVariant}
                    component="span"
                    textTransform="lowercase"
                    color="text.brandSecondary"
                  >
                    {surfHeight.min}-{surfHeight.max}
                  </Typography>
                  <Typography
                    whiteSpace="nowrap"
                    variant={unitsVariant}
                    component="span"
                    textTransform="lowercase"
                    color="text.brandSecondary"
                  >
                    {formatWaveHeight(units.waveHeight)}
                  </Typography>
                  {surfHeight.plus && (
                    <Typography
                      whiteSpace="nowrap"
                      variant={waveHeightVariant}
                      component="span"
                      textTransform="lowercase"
                      color="text.brandSecondary"
                    >
                      +
                    </Typography>
                  )}
                </Typography>
              )}
            </Stack>
            {rating && rating !== 'NONE' && (
              <Typography
                whiteSpace="nowrap"
                className={getConditionsClassName('conditionsText', rating, 'color', true, false)}
                variant={conditionsVariant}
                component="p"
                data-testid="conditions-text"
              >
                {conditionEnumToText(rating, ' ')}
              </Typography>
            )}
          </Stack>
        </Stack>
      )}
    </div>
  );
};

export default ConditionsBar;
