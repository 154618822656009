import { useRef } from 'react';
import { useRouter } from 'next/router';
import { animateScroll as scroll } from 'react-scroll';
import { useMount, useUnmount } from 'react-use';
import { getWindow } from '@surfline/web-common';

// added timeout for load race conditions issues
const WAIT_FOR_FIRST_RENDER = 500;

const updateScroll = ({ containerId, start }: { containerId?: string; start: string }) => {
  const win = getWindow();
  const element = win?.document?.getElementById(start);
  if (element) {
    const { top: elementTop = 0 } = element.getBoundingClientRect();
    let elementScrollTop: number = 0;

    if (containerId) {
      const container = win?.document?.getElementById(containerId);
      if (container) {
        const { top: containerTop = 0 } = container.getBoundingClientRect();
        elementScrollTop = elementTop - containerTop + (container.scrollTop ?? 0);
      }
    } else {
      const scrollTop = win?.pageYOffset ?? win?.document?.documentElement?.scrollTop ?? 0;
      elementScrollTop = elementTop + scrollTop;
    }

    scroll.scrollTo(elementScrollTop, {
      duration: 500,
      smooth: true,
      containerId,
    });
  }
};

const useAnalysisPageScroll = ({
  containerId,
  today,
}: {
  containerId?: string;
  today?: string;
}) => {
  const { asPath, query } = useRouter();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useMount(() => {
    const start = query.start?.toString();
    // on mount only scroll if start exists and a hash is not added
    if (start && !asPath.includes('#')) {
      const formattedStartString = /^\d/.test(start) ? `forecast-day-${start}` : start;
      // do not scroll to today on page load to avoid confusion scrolling past forecaster
      // when using forecast-today or forecast-day-{today} as the start
      if (
        formattedStartString !== 'forecast-today' &&
        formattedStartString !== `forecast-day-${today}`
      ) {
        timeoutRef.current = setTimeout(
          () => updateScroll({ containerId, start: formattedStartString }),
          WAIT_FOR_FIRST_RENDER,
        );
      }
    }
  });

  useUnmount(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
  });
};

export default useAnalysisPageScroll;
