import Button from '@mui/material/Button';
import classNames from 'classnames';
import styles from './ButtonContainer.module.scss';

// TODO - update this
type Props = any;

const ButtonContainer = ({ buttons, currentButton, onButtonClick }: Props) => (
  <div className={styles.buttonContainer}>
    {buttons.map((buttonName: string, index: number) => (
      <Button
        key={buttonName}
        onClick={() => {
          onButtonClick(index);
        }}
        variant="secondary"
        size="small"
        className={classNames(currentButton === index && styles.activeButton, styles.button)}
      >
        {buttonName}
      </Button>
    ))}
  </div>
);

export default ButtonContainer;
