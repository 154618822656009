import React, { useCallback, useEffect, useRef } from 'react';
import {
  canUseDOM,
  getWavetrakIdentity,
  getWindow,
  setAppsFlyerDevMode,
} from '@surfline/web-common';

import ErrorBoundary from 'components/ErrorBoundary';
import ErrorPage from 'containers/ErrorPage';
import useSessionTimeout from 'hooks/useSessionTimeout';
import { getStatusCode } from 'selectors/status';
import { useAppSelector } from 'stores/hooks';

const useIdentifyUser = () => {
  const analyticsReady = useAppSelector((state) => state.analytics.ready);
  // We don't need to re-render the whole tree for this value, so let's use a ref
  const hasIdentified = useRef(false);

  useEffect(() => {
    if (analyticsReady && !hasIdentified.current) {
      const win = getWindow();
      const identity = getWavetrakIdentity();

      // eslint-disable-next-line  @typescript-eslint/dot-notation
      if (canUseDOM && win?.analytics && win?.analytics['user'] && identity) {
        if (identity.type === 'logged_in' && identity.userId) {
          win.analytics.identify(identity.userId, { sourceOrigin: 'KBYG' });
          hasIdentified.current = true;
        }
      }
    }
    if (process.env.NODE_ENV !== 'production') setAppsFlyerDevMode();
  }, [analyticsReady]);
};

const KBYGPageContainer: React.FC = ({ children }) => {
  const status = useAppSelector(getStatusCode);
  useIdentifyUser();
  useSessionTimeout();

  const pageContent = useCallback(() => {
    if (status !== 200) {
      return <ErrorPage statusCode={status} />;
    }
    return children;
  }, [status, children]);

  return (
    <>
      <div className="sl-kbyg-page-container">
        <ErrorBoundary error={status === 500} errorBoundaryId="kbyg-page-container">
          {pageContent()}
        </ErrorBoundary>
      </div>
      <div id="modal-root" />
    </>
  );
};

export default KBYGPageContainer;
