import React, { useMemo } from 'react';
import classNames from 'classnames';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import { Box, Stack, Typography } from '@mui/material';

import StarCircle from 'components/Icons/StarCircle';

import styles from './SubregionForecastHighlights.module.scss';

interface Props {
  days: Array<{ date: string; dayToWatch: boolean }>;
}

const SubregionForecastHighlights: React.FC<Props> = ({ days }) => {
  const dayItems = useMemo(
    () =>
      days?.map((day) => {
        const timezone = 'UTC';
        const selectedDate = zonedTimeToUtc(`${day.date}T00:00:00`, timezone);
        const dayName = formatInTimeZone(selectedDate, timezone, 'EEE');
        const dayNumber = selectedDate.getUTCDate();
        return (
          <li key={day.date} data-testid="highlights-item">
            <Box
              className={classNames({
                [styles.day]: true,
                [styles.dayToWatch]: day.dayToWatch,
              })}
              data-testid={day.dayToWatch ? 'highlights-day-to-watch' : 'highlights-day'}
            >
              <StarCircle className={styles.star} />
              <Typography className={styles.dayName} variant="subHeadline">
                {dayName}
              </Typography>
              <Typography className={styles.dayNumber} variant="subHeadline">
                {dayNumber}
              </Typography>
            </Box>
          </li>
        );
      }),
    [days],
  );

  return (
    <Box className={styles.scrolling}>
      <Stack component="ul" className={styles.list} direction="row" spacing={1}>
        {dayItems}
      </Stack>
    </Box>
  );
};

export default SubregionForecastHighlights;
