import React, { createContext, ReactNode, useContext } from 'react';
import { isNil } from 'lodash';
import type {
  IClient,
  Treatment as SplitTreatment,
  TreatmentWithConfig as SplitTreatmentWithConfig,
} from '@splitsoftware/splitio/types/splitio';
import { TreatmentWithConfig, Treatment } from '@surfline/web-common';
import type { UserState } from 'types/user';
import { SPLIT_INCOGNITO_ID } from 'common/constants';

/**
 * Treatments type using Split.io Treatment and TreatmentWithConfig
 */

export type Treatments = {
  [featureName: string]: SplitTreatment | SplitTreatmentWithConfig;
};

/**
 * Type guard for checking if a given treatment is of the type TreatmentWithConfig
 */
export const isTreatmentWithConfig = (
  treatment: Treatment | TreatmentWithConfig,
): treatment is TreatmentWithConfig =>
  !!treatment && Object.prototype.hasOwnProperty.call(treatment as TreatmentWithConfig, 'config');

/**
 * Simple custom context for storing and recalling Treatments from split.io
 *
 * Split documentation:
 * https://help.split.io/hc/en-us/articles/360033469612-Segment-Targeting-Split-Feature-Flags-Using-Segment-Personas
 */
const TreatmentContext = createContext<Treatments>({});

interface TreatmentsProviderProps {
  children: ReactNode;
  value: Treatments;
}

/**
 * Provider to be used for storing and sharing treatments across the application
 * const treatments = await getTreatments(['treatment1', 'treatment2'], user, anonymousID)
 * <TreatmentsProvider value={treatments}>
 *   children components needing access to treatments
 * </TreatmentsProvider>
 */
export const TreatmentsProvider = ({ children, value }: TreatmentsProviderProps) => (
  <TreatmentContext.Provider value={value}>{children}</TreatmentContext.Provider>
);

interface TreatmentsConsumerProps {
  children: any;
}

/**
 * To be used for Class Components needing access to treatment values
 * <TreatmentsConsumer>
 *   {(treatments: Treatments) => (
 *     <>{treatments['treatmentName']}</>
 *   )}
 * </TreatmentsConsumer>
 */
export const TreatmentsConsumer = ({ children }: TreatmentsConsumerProps) => (
  <TreatmentContext.Consumer>
    {(context) => {
      if (context === undefined) {
        throw new Error('TreatmentsConsumer must be used within a TreatmentsProvider');
      }
      return children(context);
    }}
  </TreatmentContext.Consumer>
);

/**
 * Simple hook to retrive treatments from TreatmentProvider in Functional Components
 * const treatments = useTreatments();
 * {treatments['treatmentName'] === 'on' && <>render content</>}
 */
export const useTreatments = () => {
  const context = useContext(TreatmentContext);
  if (context === undefined) {
    throw new Error('useTreatments must be used within a TreatmentsProvider');
  }
  return context;
};

/**
 * Method to get multiple Split Treatment statuses server-side
 */
export const getTreatments = (
  splitClient: IClient,
  treatments: string[],
  userState: UserState | null,
  anonymousId: string = SPLIT_INCOGNITO_ID,
) => {
  const splitId = userState?.details?._id ?? anonymousId ?? SPLIT_INCOGNITO_ID;
  const identify = {
    id: splitId,
    userId: userState?.details?._id ?? '',
    anonymousId,
    entitlements: userState?.entitlements ?? '',
    email: userState?.details?.email ?? '',
    isoCountryCode: userState?.countryCode ?? '',
    subdivisionCode: userState?.subdivisionCode ?? '',
  };
  if (isNil(splitClient)) {
    console.warn(
      'Warning: There is no Split Client created. Be sure the env var is defined for the Split.io API Key.',
    );
    return {};
  }
  if (isNil(treatments) || treatments?.length === 0) {
    console.warn(
      'Warning: Treatments need to be specified to enable Split.io. Split.io is not enabled.',
    );
    return {};
  }
  const treatmentsWithConfig = splitClient.getTreatmentsWithConfig(splitId, treatments, identify);
  const treatmentStatus: { [key: string]: Treatment | TreatmentWithConfig } = {};
  Object.entries(treatmentsWithConfig).forEach(([treatment, status]) => {
    treatmentStatus[treatment] = status.config ? status : status.treatment;
  });
  return treatmentStatus;
};

export const parseTreatmentWithConfig = (treatment: Treatment | TreatmentWithConfig) => {
  if (
    isTreatmentWithConfig(treatment) &&
    (treatment.treatment === 'on' || treatment.treatment === 'on_premium_plus') &&
    treatment.config
  )
    try {
      return JSON.parse(treatment.config);
    } catch (error) {
      console.log(error);
    }
  return null;
};

const defaultExport = {
  TreatmentsConsumer,
  TreatmentsProvider,
  getTreatments,
  useTreatments,
  parseTreatmentWithConfig,
};

export default defaultExport;
