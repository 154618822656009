import React from 'react';
import ForecastHeader from 'components/ForecastHeader';
import { type PageType, PageTypes } from 'components/ForecastHeader/hooks/usePageDetails';
import { getSubregionOverviewData, useSubregionForecastArticles } from 'selectors/subregion';
import { useAppSelector } from 'stores/hooks';
import { getSpotDetails, getSpotReportForecast } from 'selectors/spot';

const SpotChartsPageContainer: React.FC = ({ children }) => {
  const forecastArticles = useSubregionForecastArticles();
  const spot = useAppSelector(getSpotDetails);
  const spotReportForecastData = useAppSelector(getSpotReportForecast);
  const subregionOverview = useAppSelector(getSubregionOverviewData);

  return (
    <div className="sl-subregion-forecast-page">
      <div>
        <section className="sl-section-container">
          <ForecastHeader
            conditions={spotReportForecastData?.conditions?.value}
            forecastArticles={forecastArticles}
            hasLiveCam={spot?.cameras?.length > 0}
            pageTitle={spot?.name ? `${spot?.name} Surf Report` : 'Surf Charts'}
            pageType={PageTypes.SPOT_CHARTS as PageType}
            spot={spot}
            subregionOverview={subregionOverview}
          />
        </section>
        {children}
      </div>
    </div>
  );
};

export default SpotChartsPageContainer;
