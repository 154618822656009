import { format } from 'date-fns';
import { getLocalDate } from '@surfline/web-common';

export const postedDate = (
  timestamp,
  utcOffset,
  abbrTimezone,
  // eslint-disable-next-line default-param-last
  extendedDate = false,
  dateFormat,
) => {
  const localDate = getLocalDate(timestamp, utcOffset);
  const extendedDateFormat =
    dateFormat === 'MDY' ? "MMMM, iii d 'at' h:mmaaaaa'm'" : "iii, MMMM d 'at' h:mmaaaaa'm'";
  const formatString = extendedDate ? extendedDateFormat : "iii 'at' h:mmaaaaa'm'";
  return `${format(localDate, formatString)} ${abbrTimezone}`;
};
