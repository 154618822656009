import { slugify } from '@surfline/web-common';

const forecasterNames = [
  'Charlie Hutcherson',
  'Chris Borg',
  'Jonathan Warren',
  'Keaton Browning',
  'Kevin Wallis',
  'Kurt Korte',
  'Matt Kibby',
  'Mike Watson',
  'Rob Mitstifer',
  'Schaler Perry',
  'Tim Kent',
];

const getForecasterLink = (urlBase: string, forecasterName: string) =>
  forecasterNames.some((name) => name === forecasterName)
    ? `${urlBase}/lp/forecasters/${slugify(forecasterName)}`
    : `${urlBase}/lp/forecasters`;

export default getForecasterLink;
