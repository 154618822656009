import React from 'react';

interface Props {
  className?: string;
  color?: string;
  direction?: 'up' | 'down';
}
const Thumb: React.FC<Props> = ({ className, color = '#FAFAFA', direction = 'up' }) => (
  <svg
    className={className}
    fill="none"
    height="16"
    style={{ transform: direction === 'down' ? 'rotate(180deg)' : undefined }}
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.55643 7.29996L7.40158 1C7.96751 1 8.51027 1.22125 8.91044 1.61507C9.31062 2.0089 9.53544 2.54304 9.53544 3.09999V5.89997H13.5613C13.7675 5.89767 13.9718 5.93952 14.1599 6.02262C14.3481 6.10572 14.5156 6.22808 14.6509 6.38123C14.7862 6.53437 14.8861 6.71464 14.9436 6.90954C15.0011 7.10444 15.0148 7.30931 14.9839 7.50996L14.0023 13.8099C13.9509 14.1438 13.7786 14.448 13.5172 14.6667C13.2557 14.8854 12.9228 15.0037 12.5797 14.9999H4.55643M4.55643 7.29996V14.9999M4.55643 7.29996H2.42257C2.04528 7.29996 1.68345 7.44746 1.41666 7.71001C1.14988 7.97256 1 8.32865 1 8.69995V13.5999C1 13.9712 1.14988 14.3273 1.41666 14.5899C1.68345 14.8524 2.04528 14.9999 2.42257 14.9999H4.55643"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      stroke={color}
    />
  </svg>
);

export default Thumb;
