import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { getLocalDate } from '@surfline/web-common';
import { formatDistance } from 'date-fns';
import { MDY } from '../../common/constants';
import { postedDate } from '../../utils/formatReportDate';

import styles from './LastUpdate.module.scss';

export interface LastUpdateProps {
  abbrTimezone?: string;
  dateFormat?: string;
  isExpired?: boolean;
  rawTimestampLabel?: boolean;
  timestamp: number | null;
  utcOffset?: number;
}

export const LastUpdate: React.FC<LastUpdateProps> = ({
  abbrTimezone = 'PST',
  dateFormat = MDY,
  isExpired = false,
  timestamp = null,
  rawTimestampLabel = false,
  utcOffset = -8,
}) => {
  const [showDate, setShowDate] = useState(false);

  if (!timestamp) return null;

  const timestampText = !showDate
    ? formatDistance(
        getLocalDate(timestamp, utcOffset),
        getLocalDate(Date.now() / 1000, utcOffset),
        {
          includeSeconds: true,
          addSuffix: true,
        },
      )
    : postedDate(timestamp, utcOffset, abbrTimezone, true, dateFormat);

  return (
    <button
      className={styles.button}
      data-testid="last-update"
      onClick={() => setShowDate(!showDate)}
      type="button"
    >
      <Typography
        variant={rawTimestampLabel ? 'caption1' : 'footnote'}
        color={!isExpired ? 'text.secondary' : 'error'}
      >
        {rawTimestampLabel ? timestampText : `Last updated ${timestampText}`}
      </Typography>
    </button>
  );
};

export default LastUpdate;
