import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { trackEvent, getUser } from '@surfline/web-common';
import { useMount } from 'react-use';

import { fetchSwellEvents } from 'actions/swellEvents';
import ForecastHeader from 'components/ForecastHeader';
import { type PageType, PageTypes } from 'components/ForecastHeader/hooks/usePageDetails';
import SwellEventCardContainer from 'components/SwellEventCardContainer/SwellEventCardContainer';
import LimitedTimeOfferBanner from 'components/LimitedTimeOfferBanner/LimitedTimeOfferBanner';
import { getSpotDetails, getSpotReportForecast } from 'selectors/spot';
import getSwellEvents from 'selectors/swellEvents';
import { getSubregionOverviewData, useSubregionForecastArticles } from 'selectors/subregion';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { useTreatments, parseTreatmentWithConfig } from 'utils/treatments';
import { SL_WEB_PREMIUMPLUS_LIMITED_TIME_ACCESS } from 'common/treatments';
import computeLimitedTimePremiumPlusEligibility from 'utils/computeLimitedTimePremiumPlusEligibility';
import type { SwellEvent } from 'types/swellEvents';

import styles from './SpotPageContainer.module.scss';

const SpotPageContainer: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const forecastArticles = useSubregionForecastArticles();
  const spot = useAppSelector(getSpotDetails);
  const spotReportViewData = useAppSelector(getSpotReportForecast);
  const subregionOverview = useAppSelector(getSubregionOverviewData);
  const swellEvents = useAppSelector(getSwellEvents);
  const subregionId = spot && spot.subregion ? spot.subregion._id : null;
  const treatments = useTreatments();
  const user = useAppSelector(getUser);
  const userLocation = user?.location;
  const userTimeZone = userLocation?.time_zone || 'America/Los_Angeles';
  const limitedTimePremiumPlusConfig = useMemo(
    () =>
      treatments[SL_WEB_PREMIUMPLUS_LIMITED_TIME_ACCESS]
        ? parseTreatmentWithConfig(treatments[SL_WEB_PREMIUMPLUS_LIMITED_TIME_ACCESS])
        : null,
    [treatments],
  );
  const isLimitedTimePremiumPlusEligible = useMemo(() => {
    if (limitedTimePremiumPlusConfig && treatments[SL_WEB_PREMIUMPLUS_LIMITED_TIME_ACCESS]) {
      return computeLimitedTimePremiumPlusEligibility(limitedTimePremiumPlusConfig, userTimeZone);
    }
    return false;
  }, [limitedTimePremiumPlusConfig, treatments, userTimeZone]);

  const onClickSwellEventCard = (swellEvent: SwellEvent) => {
    trackEvent('Clicked Swell Alert Card', {
      title: swellEvent.name,
      contentType: 'Swell Alert',
      basins: swellEvent.basins.join(','),
      locationCategory: 'Spot Page - Top',
      destinationUrl: swellEvent.permalink,
      subregionId,
    });
  };

  useMount(() => {
    if (spot) {
      dispatch(fetchSwellEvents(spot._id, 'spot'));
    }
  });

  return (
    <Box className={styles.pageWrapper}>
      {isLimitedTimePremiumPlusEligible && (
        <LimitedTimeOfferBanner splitConfig={limitedTimePremiumPlusConfig} />
      )}
      <section className="sl-section-container">
        {swellEvents.length ? (
          <SwellEventCardContainer onClickCard={onClickSwellEventCard} events={swellEvents} />
        ) : null}
        <ForecastHeader
          conditions={spotReportViewData?.conditions?.value}
          forecastArticles={forecastArticles}
          hasLiveCam={spot?.cameras?.length > 0}
          pageTitle={`${spot?.name} Surf Report & Forecast`}
          pageType={PageTypes.SPOT as PageType}
          spot={spot}
          subregionOverview={subregionOverview}
        />
      </section>
      {children}
    </Box>
  );
};

export default SpotPageContainer;
