import en from 'intl/translations/en';
import { Location } from 'propTypes/map/location';
import type { ForecastStatus } from 'types/spot';
import type { SubregionOverviewData } from 'types/subregion';
import subregionForecastChartsPath from 'utils/urls/forecastChartsPath';
import {
  spotForecastChartsPath,
  spotGuidePath,
  spotReportPath,
  subregionForecastPath,
} from 'utils/urls';

const isSubregionForecastActive = (subregionForecastStatus?: ForecastStatus) =>
  subregionForecastStatus === 'active';

export type PageType = 'charts' | 'spot_charts' | 'regional analysis' | 'spot' | 'spot_guide';

export const PageTypes = {
  CHARTS: 'charts',
  REGIONAL_ANALYSIS: 'regional analysis',
  SPOT: 'spot',
  SPOT_GUIDE: 'spot_guide',
  SPOT_CHARTS: 'spot_charts',
};

export const usePageDetails = ({
  hasLiveCam,
  pageType,
  subregionForecastStatus,
}: {
  hasLiveCam: boolean;
  pageType: PageType;
  subregionForecastStatus?: ForecastStatus;
}) => {
  const isForecastActive = isSubregionForecastActive(subregionForecastStatus);

  switch (pageType) {
    case 'regional analysis':
      return { tab: 1, subtitle: `${en.RegionalForecast.header.titleSuffix}` };
    case 'spot_charts':
    case 'charts':
      return {
        tab: !isForecastActive ? 1 : 2,
        subtitle: `${en.RegionalForecast.header.titleSuffix}`,
      };
    case 'spot_guide':
      return {
        tab: !isForecastActive ? 2 : 3,
        subtitle: 'Surf spot guide',
      };
    default:
      // SPOT
      return {
        tab: 0,
        subtitle: `Surf Report${hasLiveCam ? ', Surf Forecast & Surf Cam' : ' and Surf Forecast'}`,
      };
  }
};

const createLocationObject = (lat: number = 0, lon: number = 0, zoom: number = 7): Location => ({
  center: {
    lat,
    lon,
  },
  zoom,
});

export const usePageLinks = ({
  isRegionalCharts = false,
  spotId,
  spotLat,
  spotLon,
  spotName,
  subregionForecastStatus,
  subregionOverview,
  tableView = false,
}: {
  isRegionalCharts?: boolean;
  spotId?: string;
  spotLat?: number;
  spotLon?: number;
  spotName?: string;
  subregionForecastStatus?: ForecastStatus;
  subregionOverview?: SubregionOverviewData;
  tableView?: boolean;
}) => {
  /*
    SpotId & name are optional when in loading state here because react will call the hook
    we need to deal with this. It shouldnt impact anything as it will only render this whilst
    its loading and update when it has the spot details.
  */
  const DEFAULT_ZOOM = 7;
  const pageLinks = [
    {
      text: en.SpotPage.forecastHeaderLinkText.spot,
      title: `${spotName} Surf Report and Forecast`,
      path: spotReportPath(
        { view: tableView ? 'table' : undefined },
        { _id: spotId || '', name: spotName || 'spot' },
      ),
      newWindow: false,
    },
  ];

  const isForecastActive = isSubregionForecastActive(subregionForecastStatus);

  if (isForecastActive && subregionOverview?._id) {
    pageLinks.push({
      text: en.SpotPage.forecastHeaderLinkText.regionalAnalysis,
      title: `${spotName}, ${subregionOverview.name} Regional Surf Forecast`,
      path: subregionForecastPath({
        subregionId: subregionOverview._id,
        subregionName: subregionOverview.name,
        spotId,
      }),
      newWindow: false,
    });
  }
  if (subregionOverview && isRegionalCharts) {
    const { name, _id: id, spots } = subregionOverview;
    const { lat, lon } =
      spots?.find((s) => s._id === subregionOverview.primarySpot) || subregionOverview.spots[0];

    const mapTilerCharts = {
      text: en.SpotPage.forecastHeaderLinkText.charts,
      title: `${spotName} Surf Forecast Charts`,
      path: subregionForecastChartsPath(
        id,
        name || '',
        'wave-height',
        {
          ...createLocationObject(lat, lon, DEFAULT_ZOOM),
        },
        spotId,
      ),
      newWindow: false,
    };
    pageLinks.push(mapTilerCharts);
  } else {
    const spotLocation = createLocationObject(spotLat, spotLon, DEFAULT_ZOOM);
    pageLinks.push({
      text: en.SpotPage.forecastHeaderLinkText.charts,
      title: en.SpotPage.forecastHeaderLinkText.charts,
      path: spotForecastChartsPath(spotId || '', spotName || '', 'wave-height', spotLocation),
      newWindow: false,
    });
  }

  const guideRoute = {
    text: en.SpotPage.forecastHeaderLinkText.guide,
    title: en.SpotPage.forecastHeaderLinkText.guide,
    path: spotGuidePath({ spotName: spotName || '', spotId: spotId || '' }),
    newWindow: false,
  };
  pageLinks.push(guideRoute);

  return pageLinks;
};
