import React from 'react';
import classNames from 'classnames/bind';
import { Box, Stack, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { conditionClassModifier } from '@surfline/web-common';

import CloudflareResizedImage from 'components/CloudflareResizedImage';
import LastUpdate from 'components/LastUpdate';
import WavetrakLink from 'components/WavetrakLink';
import { useMaxWidthTabletLarge } from 'hooks/useMediaQueries';
import type { Forecaster } from 'types/spot';
import getForecasterLink from 'utils/getForecasterLink';

import styles from './ForecasterProfile.module.scss';

const cx = classNames.bind(styles);

const getConditionsClassName = (
  className: string,
  conditions: string,
  isShowConditionsBorder: boolean,
) =>
  cx({
    [className]: true,
    [`sl-conditions-color--${conditionClassModifier(conditions)}-border`]: isShowConditionsBorder,
    [styles.noCondition]: !isShowConditionsBorder,
  });

export interface ForecasterProfileProps extends Forecaster {
  alt?: string;
  conditions: string;
  dateFormat?: string;
  isExpired?: boolean;
  isShowConditionsBorder?: boolean;
  isShowName?: boolean;
  lastUpdate?: {
    abbrTimezone?: string;
    timestamp: number;
    utcOffset?: number;
  } | null;
  size?: 'small' | 'medium';
  titleSuffix?: React.ReactNode;
  urlBase?: string;
  useLink?: boolean;
}

export const ForecasterProfile: React.FC<ForecasterProfileProps> = ({
  alt,
  conditions,
  dateFormat,
  iconUrl,
  isExpired = false,
  isShowConditionsBorder = true,
  isShowName = true,
  lastUpdate,
  name,
  size = 'medium',
  title,
  titleSuffix,
  urlBase = 'https://www.surfline.com',
  useLink = true,
}) => {
  const isMobileView = useMaxWidthTabletLarge();
  const linkUrl = getForecasterLink(urlBase, name);

  return (
    <Stack direction="row" alignItems="center" component="figure" sx={{ margin: 0 }}>
      {useLink ? (
        <WavetrakLink
          className={styles.forecasterProfile}
          href={linkUrl}
          isExternal
          data-testid="forecaster-profile"
        >
          <div
            className={getConditionsClassName('profileImage', conditions, isShowConditionsBorder)}
          >
            <CloudflareResizedImage
              src={iconUrl}
              alt={alt || name}
              objectFit="cover"
              objectPosition="center"
              layout="fill"
              loading="lazy"
              placeholder={undefined}
            />
          </div>
        </WavetrakLink>
      ) : (
        <div className={getConditionsClassName('profileImage', conditions, isShowConditionsBorder)}>
          <CloudflareResizedImage
            src={iconUrl}
            alt={alt || name}
            objectFit="cover"
            objectPosition="center"
            layout="fill"
            loading="lazy"
            placeholder={undefined}
          />
        </div>
      )}
      {isShowName ? (
        <figcaption>
          {title ? (
            <Typography
              sx={size === 'small' ? visuallyHidden : undefined}
              variant={
                /* istanbul ignore next */
                isMobileView ? 'subHeadline' : 'headline'
              }
            >
              {title}
              {titleSuffix && <span className={styles.titleSuffix}>{titleSuffix}</span>}
            </Typography>
          ) : (
            <WavetrakLink
              className={styles.forecasterProfile}
              href={linkUrl}
              isExternal
              data-testid="forecaster-profile-name"
            >
              <Typography
                sx={size === 'small' ? visuallyHidden : undefined}
                variant={
                  /* istanbul ignore next */
                  isMobileView ? 'subHeadline' : 'headline'
                }
              >
                {name}
              </Typography>
            </WavetrakLink>
          )}
          {size === 'small' ? (
            <Typography variant="caption1" color={!isExpired ? 'text.secondary' : 'error'}>
              {title && name && (
                <>
                  <Box component="span">{name} •</Box>{' '}
                </>
              )}
              <LastUpdate
                abbrTimezone={lastUpdate?.abbrTimezone}
                isExpired={isExpired}
                timestamp={lastUpdate?.timestamp ?? null}
                utcOffset={lastUpdate?.utcOffset}
                dateFormat={dateFormat}
                rawTimestampLabel
              />
            </Typography>
          ) : (
            <>
              {title && name && (
                <>
                  <Typography component="span" variant="footnote" color="text.primary">
                    {name} •
                  </Typography>{' '}
                </>
              )}
              <LastUpdate
                abbrTimezone={lastUpdate?.abbrTimezone}
                isExpired={isExpired}
                timestamp={lastUpdate?.timestamp ?? null}
                utcOffset={lastUpdate?.utcOffset}
                dateFormat={dateFormat}
              />
            </>
          )}
        </figcaption>
      ) : null}
    </Stack>
  );
};

export default ForecasterProfile;
