import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';

import getMapImageSrc from 'utils/urls/mapTilerPath';

import styles from './SubregionForecastBackground.module.scss';

const SubregionForecastBackground: React.FC<{
  geo?: { lat?: number; lon?: number };
  mapZoom?: number;
  spotId?: string;
}> = ({ geo, mapZoom = 10, spotId }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const mapUrl = useMemo(
    () =>
      getMapImageSrc({
        geo,
        mapHeight: 600,
        mapID: 'satellite',
        mapWidth: 1400,
        mapZoom,
      }),
    [geo, mapZoom],
  );

  const mapStyle = useMemo(
    () => ({
      backgroundImage: `url(${mapUrl})`,
    }),
    [mapUrl],
  );

  // preload image before embedding so animation plays after load is complete
  useEffect(() => {
    const img = new Image();
    img.src = mapUrl;
    img.onload = () => setImageLoaded(true);

    return () => {
      img.onload = null;
      img.src = '';
    };
  }, [mapUrl]);

  return imageLoaded ? (
    <Box className={styles.mapContainer} key={`spot-id-${spotId || null}`} style={mapStyle} />
  ) : null;
};

export default SubregionForecastBackground;
