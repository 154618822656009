import { Button, Typography, IconButton, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useCallback, useMemo, useEffect } from 'react';
import WavetrakLink from 'components/WavetrakLink';
import styles from './NotificationBanner.module.scss';

interface NotificationBannerProps {
  message?: string;
  subMessage?: string;
  subMessageLink?: string;
  url: string;
  showButton?: boolean;
  buttonText?: string;
  dismissible?: boolean;
  onBannerDisplayed?: () => void;
  onButtonClick?: () => void;
}

const NotificationBanner: React.FC<NotificationBannerProps> = ({
  message,
  url,
  showButton = false,
  buttonText,
  dismissible = false,
  subMessage,
  subMessageLink,
  onBannerDisplayed,
  onButtonClick,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (isVisible && onBannerDisplayed) {
      onBannerDisplayed();
    }
  }, [isVisible, onBannerDisplayed]);

  const handleClose = useCallback(() => {
    setIsVisible(false);
  }, []);

  const handleButtonClick = useCallback(() => {
    if (onButtonClick) {
      onButtonClick();
    }
  }, [onButtonClick]);

  const alertProps = useMemo(
    () => ({
      className: showButton ? styles.notificationContentWithButton : styles.notificationContent,
      icon: false,
      ...(dismissible && {
        action: (
          <IconButton aria-label="close" onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        ),
      }),
    }),
    [dismissible, handleClose, showButton],
  );

  if (!isVisible) return null;

  return (
    <div className={styles.notificationBanner} data-testid="notification-banner">
      <Alert {...alertProps}>
        {showButton ? (
          <>
            <Typography variant="callout1" role="alert">
              {message}
            </Typography>
            <WavetrakLink href={subMessageLink || '#'} className={styles.link} target="_blank">
              <Typography variant="callout1" role="alert">
                {subMessage}
              </Typography>
            </WavetrakLink>

            <Button
              variant="secondary"
              size="small"
              className={styles.button}
              href={url}
              onClick={handleButtonClick}
            >
              <span className={styles.buttonText}>{buttonText}</span>
            </Button>
          </>
        ) : (
          <WavetrakLink href={url} className={styles.link}>
            <Typography variant="callout1" tabIndex={0} role="alert">
              {message}
            </Typography>
          </WavetrakLink>
        )}
      </Alert>
    </div>
  );
};

export default NotificationBanner;
