import { Fragment, type FunctionComponent, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useRouter } from 'next/router';
import { Box, Card, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { trackEvent } from '@surfline/web-common';

import Information from 'components/Information';
import { SubregionForecastContentPaywallDialogFooter } from 'components/SubregionForecastPaywalls';
import { PRODUCT_TUTORIALS } from 'common/constants';
import PadlockIcon from 'components/Icons/Padlock';
import ForecasterProfile from 'components/ForecasterProfile';
import SubregionForecastLink from 'components/SubregionForecastLink';
import { useUserPermissionStatus } from 'selectors/user';
import type { ForecastContent } from 'types/subregion';

import styles from './SubregionForecastContentHighlights.module.scss';

const getDayOfWeek = (dateString: string): string =>
  format(new Date(`${dateString}T00:00:00`), 'EEE');

interface Props {
  className?: string;
  elevation?: number;
  forecastContent?: ForecastContent;
  isLoading?: boolean;
}

const SubregionForecastContentHighlights: FunctionComponent<Props> = ({
  className,
  elevation,
  forecastContent,
  isLoading,
}) => {
  const { hasCoreForecastPermissions } = useUserPermissionStatus();
  const { query } = useRouter();
  const isNative = query?.native?.toString() === 'true';
  const upgradeUrl = isNative ? 'surfline://upgrade' : '/upgrade';

  const segmentPropsLock = useMemo(
    () => ({
      location: 'Forecast Content Lock',
      modalType: 'paywall',
      subregionId: forecastContent?.subregion.id,
      subregionName: forecastContent?.subregion.name,
    }),
    [forecastContent],
  );

  const onClickHandler = useCallback(() => {
    trackEvent('Clicked CTA', { ...segmentPropsLock });
  }, [segmentPropsLock]);

  const forecastHighlights = useMemo(() => {
    const highlights = forecastContent?.highlights.filter((day) => day.forecast !== null) ?? [];
    if (forecastContent?.tomorrow.forecast.dayToWatch) {
      highlights.unshift({
        date: forecastContent.tomorrow.date,
        forecast: forecastContent.tomorrow.forecast,
      });
    }
    if (forecastContent?.today.forecast.dayToWatch) {
      highlights.unshift({
        date: forecastContent.today.date,
        forecast: forecastContent.today.forecast,
      });
    }
    return highlights;
  }, [forecastContent]);

  const highlightsContent = useMemo(
    () =>
      forecastHighlights.map((day, index) => {
        const isPaywallDayUnlocked =
          index === forecastHighlights.length - 1 && forecastHighlights.length > 1;
        return (
          <Fragment key={`day-${day.date}`}>
            <Grid item mobile={2} tablet={1}>
              <Typography component="h3" variant="callout1">
                {getDayOfWeek(day.date)}:
              </Typography>
            </Grid>
            <Grid item mobile={10} tablet={11}>
              <Stack direction="row" spacing={2} alignItems="center">
                {!hasCoreForecastPermissions && (
                  <Information
                    DialogFooter={
                      <SubregionForecastContentPaywallDialogFooter
                        segmentProps={segmentPropsLock}
                        upgradeUrl={upgradeUrl}
                      />
                    }
                    articleId={PRODUCT_TUTORIALS.articles.paywallForecasterContent.id}
                    theme="cinematic"
                  >
                    <IconButton
                      aria-label="Premium content is locked. Upgrade to see it now."
                      className={classNames({
                        [styles.paywallLockButton]: true,
                        [styles.paywallLockButtonUnlocked]: isPaywallDayUnlocked,
                      })}
                      data-testid="subregion-forecast-content-highlights-paywall-button"
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      onClick={onClickHandler}
                    >
                      <PadlockIcon locked={!isPaywallDayUnlocked} />
                    </IconButton>
                  </Information>
                )}
                {(hasCoreForecastPermissions || isPaywallDayUnlocked) && (
                  <Typography component="h4" variant="body1" className={styles.cardText}>
                    {day.forecast?.headline}
                  </Typography>
                )}
              </Stack>
            </Grid>
          </Fragment>
        );
      }),
    [forecastHighlights, hasCoreForecastPermissions, onClickHandler, segmentPropsLock, upgradeUrl],
  );

  if (isLoading)
    return (
      <Skeleton
        animation="wave"
        className={styles.loadingHighlights}
        data-testid="subregion-forecast-content-highlights-loading"
        variant="rectangular"
      />
    );

  if (!forecastContent || !forecastHighlights.length) return null;

  return (
    <Card
      className={classNames(styles.forecasterContentHighlights, className)}
      component="section"
      data-testid="subregion-forecast-content-highlights"
      elevation={elevation}
    >
      <Box padding={1}>
        <Stack component="header" direction="row">
          <Box sx={{ width: '100%' }}>
            <ForecasterProfile
              conditions=""
              iconUrl={forecastContent.forecaster.avatarUrl}
              isShowConditionsBorder={false}
              isShowName
              lastUpdate={{ timestamp: forecastContent.lastPublished }}
              name={forecastContent.forecaster.name}
              title="Days to watch"
            />
          </Box>
          <Box>
            <SubregionForecastLink
              start="forecast-highlights"
              subregionId={forecastContent.subregion.id}
              subregionName={forecastContent.subregion.name}
            />
          </Box>
        </Stack>
        <Grid container spacing={2} mt={2}>
          {highlightsContent}
        </Grid>
      </Box>
    </Card>
  );
};

export default SubregionForecastContentHighlights;
