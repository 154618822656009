import React, {
  type CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  dismissContentCard,
  getWindow,
  trackContentCardClick,
  trackContentCardImpressions,
  trackEvent,
} from '@surfline/web-common';
import usePageName from 'hooks/usePageName';

import { CLICKED_SUBSCRIBE_CTA, BRAZE_CONTENT_CARDS } from 'common/constants';
import TrackableLink from 'components/TrackableLink';
import withContentCards from 'components/withContentCards';
import getAccountSharingBannerProps from 'utils/getAccountSharingBannerProps';

import Notification from '../Notification';
import NotificationBanner from '../NavigationBar/components/NotificationBanner';

import styles from './NotificationBarController.module.scss';

const SUBSCRIBE_CTA_PROPERTIES = { location: 'upsell bar' };

interface NotificationBarControllerProps {
  card?: {
    name: string;
    extras: {
      css: CSSProperties;
      href__text: string;
      href__url: string;
    } | null;
  };
  slDisableNotificationCTA?: string;
  warning?: {
    alert: boolean;
    message: string;
    type: string;
  };
  user?: {
    isAccountInShareWarningMode?: boolean;
    isAccountLockedFromSharing?: boolean;
  };
  entitlements?: Array<string>;
  accountSharingBannerConfig?: {
    [key: string]: {
      ctaBodyText: string;
      ctaBodyLinkText?: string;
      ctaBodyLinkHref?: string;
      ctaButtonText: string;
      discountCode: string;
      discountUrl: string;
    };
  };
}

const NotificationBarController: React.FC<NotificationBarControllerProps> = ({
  card = { name: BRAZE_CONTENT_CARDS.NOTIFICATION_BAR, extras: null },
  slDisableNotificationCTA = 'off',
  warning,
  user,
  entitlements,
  accountSharingBannerConfig,
}) => {
  const freeTrialLinkRef = useRef(null);
  const [rendered, setRendered] = useState(false);
  const pageName = usePageName();
  const showAccountSharingBanner =
    user?.isAccountInShareWarningMode === true && user?.isAccountLockedFromSharing === false;
  const handleClickCTA = useCallback(
    (e) => {
      e.preventDefault();
      trackContentCardClick(card);
      setTimeout(() => {
        if (card?.extras?.href__url) {
          getWindow()?.location.assign(card.extras.href__url);
        }
      });
    },
    [card],
  );

  const accountSharingBannerProps = useMemo(() => {
    if (showAccountSharingBanner && accountSharingBannerConfig && entitlements) {
      return getAccountSharingBannerProps({ accountSharingBannerConfig, entitlements });
    }
    return null;
  }, [showAccountSharingBanner, accountSharingBannerConfig, entitlements]);

  const handleAccountSharingBannerDisplayed = useCallback(() => {
    trackEvent('Banner Displayed', {
      bannerText: accountSharingBannerProps?.ctaBodyText,
      screenName: pageName,
    });
  }, [pageName, accountSharingBannerProps]);

  const handleAccountSharingButtonClick = useCallback(() => {
    trackEvent('Button Clicked', {
      buttonText: accountSharingBannerProps?.ctaButtonText,
      pageName,
      uiElement: 'Account Sharing Banner',
    });
  }, [pageName, accountSharingBannerProps]);

  const renderBrazeNotification = useMemo(
    () =>
      card?.extras && (
        <Notification
          closable
          type="large"
          level="information"
          onCloseComplete={() => dismissContentCard(BRAZE_CONTENT_CARDS.NOTIFICATION_BAR)}
        >
          <TrackableLink
            ref={freeTrialLinkRef}
            eventName={CLICKED_SUBSCRIBE_CTA}
            eventProperties={SUBSCRIBE_CTA_PROPERTIES}
          >
            <a
              ref={freeTrialLinkRef}
              href={card.extras.href__url}
              className="quiver-notification__tag-line"
              style={{ ...card.extras.css }}
              onClick={handleClickCTA}
            >
              {card.extras.href__text}
            </a>
          </TrackableLink>
        </Notification>
      ),
    [card, handleClickCTA],
  );
  const renderBanners = useMemo(
    () => (
      <>
        {warning?.alert && warning?.message && (
          <div>
            <NotificationBanner
              message={warning.message}
              url="/account/subscription#payment-details"
              dismissible
            />
          </div>
        )}
        {showAccountSharingBanner && accountSharingBannerProps && (
          <div>
            <NotificationBanner
              message={accountSharingBannerProps.ctaBodyText}
              subMessage={accountSharingBannerProps.ctaBodyLinkText}
              subMessageLink={accountSharingBannerProps.ctaBodyLinkHref}
              url={accountSharingBannerProps.discountUrl}
              showButton
              buttonText={accountSharingBannerProps.ctaButtonText}
              onBannerDisplayed={handleAccountSharingBannerDisplayed}
              onButtonClick={handleAccountSharingButtonClick}
            />
          </div>
        )}
      </>
    ),
    [
      warning,
      showAccountSharingBanner,
      accountSharingBannerProps,
      handleAccountSharingBannerDisplayed,
      handleAccountSharingButtonClick,
    ],
  );

  const renderNotification = useMemo(() => {
    if (slDisableNotificationCTA === 'on') return null;
    if (warning?.alert || showAccountSharingBanner) {
      return renderBanners;
    }
    if (card?.extras !== null) {
      if (!rendered) {
        setRendered(true);
        trackContentCardImpressions([card]);
      }
      return renderBrazeNotification;
    }
    return null;
  }, [
    card,
    rendered,
    renderBrazeNotification,
    slDisableNotificationCTA,
    warning,
    showAccountSharingBanner,
    renderBanners,
  ]);

  const [notification, setNotification] = useState(renderNotification);
  // Updates the bar when the Braze card is set
  useEffect(() => {
    setNotification(renderNotification);
  }, [renderNotification]);

  return (
    <div className={styles.notificaitonBarController}>
      <div className="quiver-notification-bar">{notification}</div>
    </div>
  );
};

export default withContentCards(NotificationBarController);
