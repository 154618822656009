const getTutorialArticlesQuery = (id: string | undefined) =>
  JSON.stringify({
    query: `query GetTutorial($id: String!) {
          tutorial(id: $id) {
            title
            size
            articlesCollection(limit: 10) {
              items {
                sys {
                  id
                }
                title
                topic
                body {
                  json
                  links {
                    assets {
                      block {
                        fileName
                        title
                        description
                        url
                        height
                        width
                        sys {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
    variables: {
      id,
    },
  });

export default getTutorialArticlesQuery;
