import React, { useEffect, useRef } from 'react';
import { useUnmount } from 'react-use';
import VideoJsPlayer from 'video.js/dist/types/player';
import videojs from 'utils/videojsWrapper';

import ErrorBoundary from 'components/ErrorBoundary';
import ErrorMessage from 'components/ErrorMessage';
import classNames from 'classnames';

import styles from './VideoPlaylistPlayer.module.scss';

interface VideoPlayerContentProps {
  id: string;
  src: string;
  visible?: boolean;
  onEnded?: () => void;
}

const VideoPlayerContent: React.FC<VideoPlayerContentProps> = ({
  id,
  src,
  visible,
  onEnded = () => {},
}) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<VideoJsPlayer | null>(null);
  useEffect(() => {
    if (videoRef.current) {
      let player: any;
      if (!playerRef.current) {
        const videoElement = window.document.createElement('video-js');
        videoElement.setAttribute('id', id);
        videoRef.current.appendChild(videoElement);

        player = videojs(videoElement, {
          aspectRatio: '16:9',
          autoplay: true,
          controls: false,
          disablePictureInPicture: true,
          muted: true,
          preload: 'auto',
          sources: [{ src, type: 'video/mp4' }],
          playsinline: true,
        });
        player.addClass('vjs-big-play-centered vjs-sl-cam-player');
        playerRef.current = player;
      } else {
        player = playerRef.current;
        player.src([{ src, type: 'video/mp4' }]);
      }
      player.on('ended', onEnded);
      player.addClass('vjs-sl-cam-player-none');

      return () => {
        player.off('ended', onEnded);
      };
    }

    return () => {};
  }, [id, src, onEnded]);

  useUnmount(() => {
    const player = playerRef.current;
    if (player && !player.isDisposed()) {
      try {
        player.dispose();
        playerRef.current = null;
      } catch (error) {
        console?.error(error);
      }
    }
  });

  useEffect(() => {
    const player = playerRef.current;
    if (player) {
      if (visible) {
        player.play();
      } else {
        player.pause();
        player.currentTime(0);
      }
    }
  }, [visible, playerRef]);

  return (
    <div
      className={classNames(styles.VideoPlayer, {
        [styles.hide]: !visible,
      })}
      ref={videoRef}
    />
  );
};

const VideoPlayerContentMemo = React.memo(VideoPlayerContent);

export interface VideoPlayerProps {
  error?: { status: number; name: string; message: string };
  playlist: string[];
  currentPlayer: number;
  setCurrentPlayer: (index: number) => void;
}

export const VideoPlaylistPlayer: React.FC<VideoPlayerProps> = ({
  playlist,
  error,
  currentPlayer,
  setCurrentPlayer,
}) => (
  <ErrorBoundary
    error={error}
    errorBoundaryId="video-playlist-player"
    render={() => <ErrorMessage />}
  >
    {playlist.map((src, index) => (
      <VideoPlayerContentMemo
        onEnded={() => {
          if (currentPlayer < playlist.length - 1) {
            setCurrentPlayer(currentPlayer + 1);
          }
        }}
        id={`video-playlist-player-${src}-${index}`}
        visible={currentPlayer === index}
        src={src}
        // eslint-disable-next-line react/no-array-index-key
        key={`${src}-${index}`}
      />
    ))}
  </ErrorBoundary>
);

export default VideoPlaylistPlayer;
