import { Fragment, type FunctionComponent, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useRouter } from 'next/router';
import { Box, Button, Card, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { trackEvent } from '@surfline/web-common';

import StarCircle from 'components/Icons/StarCircle';
import Information from 'components/Information';
import { SubregionForecastContentPaywallDialogFooter } from 'components/SubregionForecastPaywalls';
import { PRODUCT_TUTORIALS } from 'common/constants';
import PadlockIcon from 'components/Icons/Padlock';
import ForecasterProfile from 'components/ForecasterProfile';
import SubregionForecastLink from 'components/SubregionForecastLink';
import { useUserPermissionStatus } from 'selectors/user';
import type { ForecastContent } from 'types/subregion';

import styles from './SubregionForecastContentGrouped.module.scss';

const getDayOfWeek = (dateString: string): string =>
  format(new Date(`${dateString}T00:00:00`), 'EEE');

interface Props {
  className?: string;
  elevation?: number;
  forecastContent?: ForecastContent;
  forecastNote?: string | null;
  isLoading?: boolean;
}

const SubregionForecastContentGrouped: FunctionComponent<Props> = ({
  className,
  elevation,
  forecastContent,
  forecastNote,
  isLoading,
}) => {
  const { query } = useRouter();
  const { hasCoreForecastPermissions } = useUserPermissionStatus();

  const isNative = query?.native?.toString() === 'true';
  const upgradeUrl = isNative ? 'surfline://upgrade' : '/upgrade';

  const isAfter2pm = useMemo(() => {
    const forecastTimezone = forecastContent?.timezone;
    if (!forecastTimezone) return false;
    const nowInForecastTimezone = utcToZonedTime(new Date(), forecastTimezone);
    const after2pm = nowInForecastTimezone.getHours() >= 14;
    return after2pm;
  }, [forecastContent]);

  const nowTitle = useMemo(() => {
    if (!forecastContent) return null;
    const forecast = forecastContent.tomorrowIsLive
      ? forecastContent.tomorrow
      : forecastContent.today;
    return (
      <>
        {forecast.forecast.dayToWatch && <StarCircle />}{' '}
        {forecastContent.tomorrowIsLive ? 'Tomorrow' : 'Today'}
      </>
    );
  }, [forecastContent]);

  const segmentPropsLock = useMemo(
    () => ({
      location: 'Forecast Content Lock',
      modalType: 'paywall',
      subregionId: forecastContent?.subregion.id,
      subregionName: forecastContent?.subregion.name,
    }),
    [forecastContent],
  );

  const onClickHandler = useCallback(() => {
    trackEvent('Clicked CTA', { ...segmentPropsLock });
  }, [segmentPropsLock]);

  const forecastHighlights = useMemo(() => {
    const highlights = forecastContent?.highlights.filter((day) => day.forecast !== null) ?? [];
    // add tomorrow to highlights if we're viewing today in the Now content area and tomorrow is a day to watch
    if (
      forecastContent?.tomorrowIsLive === false &&
      forecastContent?.tomorrow.forecast.dayToWatch
    ) {
      highlights.unshift({
        date: forecastContent.tomorrow.date,
        forecast: forecastContent.tomorrow.forecast,
      });
    }
    return highlights;
  }, [forecastContent]);

  const highlightsContent = useMemo(
    () =>
      forecastHighlights.map((day, index) => {
        const isPaywallDayUnlocked =
          index === forecastHighlights.length - 1 && forecastHighlights.length > 1;
        return (
          <Fragment key={`day-${day.date}`}>
            <Grid item mobile={2} desktop={1}>
              <Typography component="h3" variant="callout1">
                {getDayOfWeek(day.date)}:
              </Typography>
            </Grid>
            <Grid item mobile={10} desktop={11}>
              <Stack direction="row" spacing={2} alignItems="center">
                {!hasCoreForecastPermissions && (
                  <Information
                    DialogFooter={
                      <SubregionForecastContentPaywallDialogFooter
                        segmentProps={segmentPropsLock}
                        upgradeUrl={upgradeUrl}
                      />
                    }
                    articleId={PRODUCT_TUTORIALS.articles.paywallForecasterContent.id}
                    theme="cinematic"
                  >
                    <IconButton
                      aria-label="Premium content is locked. Upgrade to see it now."
                      className={classNames({
                        [styles.paywallLockButton]: true,
                        [styles.paywallLockButtonUnlocked]: isPaywallDayUnlocked,
                      })}
                      data-testid="subregion-forecast-content-grouped-paywall-button"
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      onClick={onClickHandler}
                    >
                      <PadlockIcon locked={!isPaywallDayUnlocked} />
                    </IconButton>
                  </Information>
                )}
                {(hasCoreForecastPermissions || isPaywallDayUnlocked) && (
                  <Typography component="h4" variant="body1" className={styles.cardText}>
                    {day.forecast?.headline}
                  </Typography>
                )}
              </Stack>
            </Grid>
          </Fragment>
        );
      }),
    [forecastHighlights, hasCoreForecastPermissions, onClickHandler, segmentPropsLock, upgradeUrl],
  );

  if (isLoading)
    return (
      <Skeleton
        animation="wave"
        className={styles.loadingGrouped}
        variant="rectangular"
        data-testid="subregion-forecast-content-grouped-loading"
      />
    );

  if (!forecastContent) return null;

  return (
    <Card
      className={classNames(styles.forecasterContentGrouped, className)}
      component="section"
      data-testid="subregion-forecast-content-grouped"
      elevation={elevation}
    >
      <Grid container spacing={10} padding={2}>
        <Grid item mobile={6}>
          <Box className={styles.gridBorder}>
            <Stack component="header" direction="row" mb={2}>
              <Box sx={{ width: '100%' }}>
                <ForecasterProfile
                  conditions=""
                  iconUrl={forecastContent.forecaster.avatarUrl}
                  lastUpdate={{ timestamp: forecastContent.lastPublished }}
                  name={forecastContent.forecaster.name}
                  isShowConditionsBorder={false}
                  title={`${forecastContent.subregion.name} Forecast`}
                  isShowName
                />
                <Typography component="h3" variant="headline" mt={1}>
                  {nowTitle}
                </Typography>
              </Box>
              <Box>
                <SubregionForecastLink
                  start={forecastContent.tomorrowIsLive ? 'forecast-tomorrow' : 'forecast-today'}
                  subregionId={forecastContent.subregion.id}
                  subregionName={forecastContent.subregion.name}
                />
              </Box>
            </Stack>
            {!hasCoreForecastPermissions && (
              <>
                {!isAfter2pm && !forecastContent.tomorrowIsLive && (
                  <Typography component="h5" variant="body" mb={2}>
                    Today&apos;s Forecast will be available to you at 2pm.
                  </Typography>
                )}
                <Stack
                  direction="row"
                  spacing={1}
                  mt={2}
                  mb={isAfter2pm || forecastContent.tomorrowIsLive ? 2 : 0}
                >
                  <Information
                    DialogFooter={
                      <SubregionForecastContentPaywallDialogFooter
                        segmentProps={segmentPropsLock}
                        upgradeUrl={upgradeUrl}
                      />
                    }
                    articleId={PRODUCT_TUTORIALS.articles.paywallForecasterContent.id}
                    theme="cinematic"
                  >
                    <IconButton
                      aria-label="Premium content is locked until 2pm. Upgrade to see it now."
                      className={classNames({
                        [styles.paywallLockButton]: true,
                        [styles.paywallLockButtonUnlocked]:
                          isAfter2pm && !forecastContent.tomorrowIsLive,
                      })}
                      data-testid="subregion-forecast-content-grouped-paywall-button"
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                    >
                      <PadlockIcon locked={!isAfter2pm || forecastContent.tomorrowIsLive} />
                    </IconButton>
                  </Information>
                  <Button
                    data-testid="subregion-forecast-content-grouped-upgrade-button"
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    href={upgradeUrl}
                    variant="primary"
                  >
                    {isAfter2pm && !forecastContent.tomorrowIsLive
                      ? 'Get it earlier with Premium'
                      : 'Upgrade to see it now'}
                  </Button>
                </Stack>
              </>
            )}
            {(hasCoreForecastPermissions || (isAfter2pm && !forecastContent.tomorrowIsLive)) && (
              <>
                <Typography component="p" variant="subtitle1" my={2}>
                  {forecastContent.tomorrowIsLive
                    ? forecastContent.tomorrow.forecast.headline
                    : forecastContent.today.forecast.headline}
                </Typography>
                <Typography component="p" variant="body1">
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: forecastContent.tomorrowIsLive
                        ? forecastContent.tomorrow.forecast.observation
                        : forecastContent.today.forecast.observation,
                    }}
                  />
                </Typography>
                {forecastNote && (
                  <Typography component="div" variant="body1" mt={2}>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{ __html: forecastNote }} />
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Grid>
        {forecastHighlights.length && (
          <Grid item mobile={6}>
            <Stack component="header" direction="row" mb={2}>
              <Box sx={{ width: '100%' }}>
                <Typography component="h4" variant="headline">
                  Days to watch
                </Typography>
              </Box>
              <Box>
                <SubregionForecastLink
                  start="forecast-highlights"
                  subregionId={forecastContent.subregion.id}
                  subregionName={forecastContent.subregion.name}
                />
              </Box>
            </Stack>
            <Grid container spacing={2}>
              {highlightsContent}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default SubregionForecastContentGrouped;
