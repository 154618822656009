import React from 'react';

interface ShakaProps {
  fill?: string;
  size?: number;
}

const Shaka = ({ fill = '#FAFAFA', size = 16 }: ShakaProps) => (
  <svg
    fill="none"
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M6.12174 0C5.43019 0 4.86957 0.586109 4.86957 1.30909V2.94964C4.86957 3.28833 4.79412 3.6224 4.64925 3.92535L3.04831 7.27273H1.3913C0.622922 7.27273 0 7.92396 0 8.72727V13.8182C0 15.0232 0.934365 16 2.08696 16H3.47826H4.17391H11.1304H13.913C15.0656 16 16 15.0232 16 13.8182C16 12.6132 15.0656 11.6364 13.913 11.6364H12.9511L13.3035 8.32124C13.4866 6.598 12.1963 5.09091 10.5379 5.09091H9.04348V3.05455C9.04348 1.36756 7.73537 0 6.12174 0ZM4.17391 14.5455H11.1304H13.913C14.2973 14.5455 14.6087 14.2199 14.6087 13.8182C14.6087 13.4165 14.2973 13.0909 13.913 13.0909H12.1739C11.976 13.0909 11.7875 13.0028 11.6555 12.8487C11.5236 12.6945 11.4607 12.4889 11.4825 12.2833L11.9207 8.16062C12.0122 7.29902 11.3671 6.54545 10.5379 6.54545H8.34783C7.96362 6.54545 7.65217 6.21985 7.65217 5.81818V3.05455C7.65217 2.21993 7.0409 1.53454 6.26087 1.46105V2.94964C6.26087 3.51415 6.13513 4.07091 5.89367 4.57585L4.17391 8.17167V14.5455ZM2.78261 14.5455V8.72727H1.3913V13.8182C1.3913 14.2199 1.70275 14.5455 2.08696 14.5455H2.78261Z"
      fillRule="evenodd"
      fill={fill}
    />
  </svg>
);

export default Shaka;
