import config from 'config';

type AccountSharingBannerConfig = {
  [key: string]: {
    ctaBodyText: string;
    ctaBodyLinkText?: string;
    ctaBodyLinkHref?: string;
    ctaButtonText: string;
    discountCode: string;
    discountUrl: string;
  };
};

type BannerProps = {
  ctaBodyText?: string;
  ctaBodyLinkText?: string;
  ctaBodyLinkHref?: string;
  ctaButtonText?: string;
  discountCode?: string;
  discountUrl: string;
} | null;

type GetAccountSharingBannerPropsParams = {
  accountSharingBannerConfig: AccountSharingBannerConfig;
  entitlements: string[];
};

const getAccountSharingBannerProps = ({
  accountSharingBannerConfig,
  entitlements,
}: GetAccountSharingBannerPropsParams): BannerProps => {
  let bannerProps: BannerProps = null;

  if (accountSharingBannerConfig && entitlements) {
    const found = entitlements.some((entitlement) => {
      const splitConfig = accountSharingBannerConfig[entitlement];
      if (splitConfig) {
        const discountUrl = `${config.surflineHost}/logout?redirectTo=upgrade&accountSharing=true&discountCode=${splitConfig?.discountCode}`;
        bannerProps = {
          ctaBodyText: splitConfig?.ctaBodyText,
          ctaBodyLinkText: splitConfig?.ctaBodyLinkText,
          ctaBodyLinkHref: splitConfig?.ctaBodyLinkHref,
          ctaButtonText: splitConfig?.ctaButtonText,
          discountCode: splitConfig?.discountCode,
          discountUrl,
        };
        return true;
      }
      return false;
    });

    if (!found) {
      bannerProps = null;
    }
  }

  return bannerProps;
};

export default getAccountSharingBannerProps;
