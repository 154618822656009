const getArticleQuery = (id: string | undefined) =>
  JSON.stringify({
    query: `query GetArticle($id: String!) {
      tutorialArticle(id: $id) {
        title
        body {
          json
          links {
            assets {
              block {
                fileName
                title
                description
                url
                height
                width
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }`,
    variables: {
      id,
    },
  });

export default getArticleQuery;
