import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { format } from 'date-fns-tz';
import { useSessionStorage } from 'react-use';
import { IconButton, Typography } from '@mui/material';
import { trackEvent } from '@surfline/web-common';

import Thumb from 'components/Icons/Thumb';
import Shaka from 'components/Icons/Shaka';

import styles from './SubregionForecastFeedback.module.scss';

interface Props {
  date: Date;
  forecasterName: string;
  spotId?: string;
  spotName?: string;
  subregionId: string;
  subregionName: string;
  timezone: string;
  type: 'today' | 'tomorrow' | 'highlights';
}

const SubregionForecastFeedback: React.FC<Props> = ({
  date,
  forecasterName,
  spotId,
  spotName,
  subregionId,
  subregionName,
  timezone,
  type,
}) => {
  const formattedDate = useMemo(
    () => format(date, 'MM-dd-yyyy', { timeZone: timezone }),
    [date, timezone],
  );

  const [feedback, setFeedback] = useSessionStorage<'yes' | 'no' | null>(
    `sl_subregionFeedback_${subregionId}_${formattedDate}${
      type === 'highlights' ? '_highlights' : ''
    }`,
    null,
  );

  const message = useMemo(() => {
    if (feedback === 'yes') return 'Stoked, hope you score!';
    if (feedback === 'no') return 'Thank you for the feedback.';
    return 'Was this forecast information useful?';
  }, [feedback]);

  const onClickYesHandler = useCallback(() => {
    setFeedback('yes');
    trackEvent('Content Feedback Submitted', {
      contentBlock: type,
      forecasterName,
      isUseful: true,
      spotId,
      spotName,
      subregionId,
      subregionName,
    });
  }, [type, forecasterName, setFeedback, spotId, spotName, subregionId, subregionName]);

  const onClickNoHandler = useCallback(() => {
    setFeedback('no');
    trackEvent('Content Feedback Submitted', {
      contentBlock: type,
      forecasterName,
      isUseful: false,
      spotId,
      spotName,
      subregionId,
      subregionName,
    });
  }, [type, forecasterName, setFeedback, spotId, spotName, subregionId, subregionName]);

  return (
    <div className={styles.wrapper} data-testid="subregion-forecast-feedback">
      <Typography
        className={styles.message}
        component="p"
        data-testid="feedback-message"
        mr={2}
        variant="footnote"
      >
        {message}
      </Typography>
      <div className={styles.actions}>
        {feedback !== 'yes' && feedback !== 'no' && (
          <>
            <IconButton
              className={styles.feedbackIcon}
              data-testid="feedback-button-yes"
              onClick={onClickYesHandler}
              size="medium"
            >
              <Thumb direction="up" />
            </IconButton>
            <IconButton
              className={styles.feedbackIcon}
              data-testid="feedback-button-no"
              onClick={onClickNoHandler}
              size="medium"
            >
              <Thumb direction="down" />
            </IconButton>
          </>
        )}
        {feedback === 'yes' && (
          <div className={classNames(styles.feedbackIcon, styles.feedbackPositive)}>
            <Shaka />
          </div>
        )}
        {feedback === 'no' && (
          <div className={classNames(styles.feedbackIcon, styles.feedbackNegative)}>
            <Shaka />
          </div>
        )}
      </div>
    </div>
  );
};

export default SubregionForecastFeedback;
