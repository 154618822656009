import React, { Component, ReactNode } from 'react';
import ErrorFiveHundred from 'components/ErrorFiveHundred';

interface Props {
  children: ReactNode;
  error?: string | boolean | Error;
  errorBoundaryId: string;
  errorsToSuppress?: string[];
  properties?: {
    componentStack?: string;
    [key: string]: any;
  };
  render?: () => ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    const { hasError } = this.state;
    const { errorBoundaryId, properties, errorsToSuppress = [] } = this.props;

    const propsToSendToNewRelic = { ...properties };
    if (!hasError) this.setState({ hasError: true });

    console.error('ErrorBoundary.componentDidCatch', error, errorInfo);
    if (window && window.newrelic) {
      if (errorInfo?.componentStack) {
        propsToSendToNewRelic.componentStack = errorInfo.componentStack.toString();
      }
      propsToSendToNewRelic.errorBoundaryId = errorBoundaryId;
      propsToSendToNewRelic.source = 'ErrorBoundary';
      const errorMessage = error?.message ?? error.toString();
      if (errorsToSuppress?.includes?.(errorMessage)) {
        window.newrelic?.recordCustomEvent?.(errorMessage, { error, ...propsToSendToNewRelic });
      } else {
        window.newrelic?.noticeError?.(error, propsToSendToNewRelic);
      }
    }
  }

  render() {
    const { children, render, error } = this.props;
    const { hasError } = this.state;

    if (hasError || error) {
      if (render) {
        return render();
      }
      return <ErrorFiveHundred />;
    }

    return children;
  }
}

export default ErrorBoundary;
