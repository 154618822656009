import { Reducer } from 'redux';
import type {
  SpotReportState,
  SpotReportAssociated,
  SpotReportStateData,
  SpotForecast,
} from '../../types/spot';
import createReducer from '../createReducer';
import {
  FETCH_SPOT_REPORT,
  FETCH_SPOT_REPORT_SUCCESS,
  UPDATE_SPOT_REPORT_FORECAST,
} from '../../actions/spot';
import { LEAVE_SPOT_ROUTE } from '../../actions/routes';

export const initialState = {};

const fetchSpotReport: Reducer<Readonly<SpotReportState>> = (state, { loading }) => ({
  ...state,
  loading,
});

const fetchSpotReportSuccess: Reducer<
  Readonly<SpotReportState>,
  {
    type: string;
    data: SpotReportStateData;
    associated: SpotReportAssociated;
    legacySpotId: string;
    legacySubregionId: string;
  }
> = (state, { data, associated, legacySpotId, legacySubregionId }) => ({
  ...state,
  data: {
    ...data,
    forecast: {
      ...data.forecast,
      swells: data.forecast.swells.map((swell, index) => ({ ...swell, index })),
    },
  },
  associated,
  legacySpotId,
  legacySubregionId,
  loading: false,
});

const updateSpotReportForecast: Reducer<
  Readonly<SpotReportState>,
  {
    type: string;
    data: SpotForecast;
  }
> = (state, { data }) => ({
  ...(state ?? initialState),
  data: {
    ...(state?.data as SpotReportStateData),
    forecast: {
      ...state?.data?.forecast,
      ...data,
    },
  },
});

const leaveSpotRoute: Reducer<Readonly<SpotReportState>> = () => initialState;

export default createReducer(
  {
    [FETCH_SPOT_REPORT]: fetchSpotReport,
    [FETCH_SPOT_REPORT_SUCCESS]: fetchSpotReportSuccess,
    [LEAVE_SPOT_ROUTE]: leaveSpotRoute,
    [UPDATE_SPOT_REPORT_FORECAST]: updateSpotReportForecast,
  },
  initialState,
);
