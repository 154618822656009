import React from 'react';

const StarCircle: React.FC<{ className?: string; fill?: string; testId?: string }> = ({
  className,
  fill = '#272727',
  testId,
}) => (
  <svg
    className={className}
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId || 'icon-star-circle'}
  >
    <path
      d="M8.00002 0C6.41777 -4.21931e-06 4.87105 0.469184 3.55546 1.34823C2.23986 2.22728 1.21448 3.47671 0.608973 4.93852C0.00346904 6.40032 -0.154961 8.00886 0.153719 9.56071C0.462398 11.1126 1.22432 12.538 2.34314 13.6568C3.46196 14.7757 4.88742 15.5376 6.43927 15.8463C7.99112 16.155 9.59965 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84345 13.6569 2.34316C12.1566 0.842868 10.1217 9.90139e-06 8.00002 0ZM11.0226 8.98209L11.9037 13.373L8.00002 11.1781L4.09629 13.373L4.97745 8.98209L1.68364 5.94769L6.13199 5.42886L8.00002 1.35858L9.86806 5.42886L14.3164 5.94769L11.0226 8.98209Z"
      fill={fill}
    />
  </svg>
);

export default StarCircle;
