import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { Box, Skeleton, Stack, Typography } from '@mui/material';

import CloudflareResizedImage from 'components/CloudflareResizedImage';
import { SurflineWaterDrop } from 'components/Icons/SurflineWaterDrop';
import WavetrakLink from 'components/WavetrakLink';
import config from 'config';
import {
  getForecastContent,
  getForecastContentLoading,
  getSubregionOverviewData,
} from 'selectors/subregion';
import { useAppSelector } from 'stores/hooks';
import type { ForecastContent, SubregionOverviewData } from 'types/subregion';
import getForecasterLink from 'utils/getForecasterLink';

import styles from './SubregionForecaster.module.scss';

const SubregionForecaster: FunctionComponent = () => {
  const { query } = useRouter();
  const forecastContent: ForecastContent = useAppSelector(getForecastContent);
  const forecastContentLoading: boolean = useAppSelector(getForecastContentLoading);
  const subregionOverview: SubregionOverviewData = useAppSelector(getSubregionOverviewData);
  const [imageLoaded, setImageLoaded] = useState(false);

  const isNative = query?.native?.toString() === 'true';

  const forecasterUrl = useMemo(
    () => getForecasterLink(config.surflineHost, forecastContent?.forecaster.name),
    [forecastContent?.forecaster.name],
  );

  const forecasterImage = useMemo(() => {
    if (!forecastContent) return null;
    if (!imageLoaded) {
      return (
        <Box className={styles.forecasterImage} data-testid="subregion-forecaster-image-no-link">
          <Skeleton
            animation="pulse"
            className={styles.loading}
            height={72}
            variant="circular"
            width={72}
          />
        </Box>
      );
    }
    if (isNative) {
      return (
        <Box className={styles.forecasterImage} data-testid="subregion-forecaster-image-no-link">
          <CloudflareResizedImage
            src={forecastContent.forecaster.avatarUrl}
            alt={`${forecastContent.forecaster.name} Portrait`}
            objectFit="cover"
            objectPosition="center"
            layout="fill"
            loading="lazy"
            placeholder={undefined}
          />
        </Box>
      );
    }
    return (
      <WavetrakLink
        className={styles.forecasterImage}
        data-testid="subregion-forecaster-image-link"
        href={forecasterUrl}
        isExternal
      >
        <CloudflareResizedImage
          src={forecastContent.forecaster.avatarUrl}
          alt={`${forecastContent.forecaster.name} Portrait`}
          objectFit="cover"
          objectPosition="center"
          layout="fill"
          loading="lazy"
          placeholder={undefined}
        />
      </WavetrakLink>
    );
  }, [forecastContent, forecasterUrl, imageLoaded, isNative]);

  // preload image before embedding so animation plays after load is complete
  useEffect(() => {
    const img = new Image();
    img.onload = () => setImageLoaded(true);
    if (forecastContent?.forecaster?.avatarUrl) img.src = forecastContent.forecaster.avatarUrl;

    return () => {
      img.onload = null;
      img.src = '';
    };
  }, [forecastContent]);

  if (forecastContentLoading) {
    return (
      <Stack
        className={styles.wrapper}
        data-testid="subregion-forecaster-loading"
        direction="column"
        width="100%"
        alignItems="center"
      >
        <Skeleton
          animation="pulse"
          className={styles.loading}
          height={72}
          variant="circular"
          width={72}
        />
        <Skeleton
          animation="pulse"
          className={styles.loading}
          height={22}
          variant="text"
          width={100}
        />
        <Skeleton
          animation="pulse"
          className={styles.loading}
          height={15}
          variant="text"
          width={150}
        />
      </Stack>
    );
  }

  if (!forecastContentLoading && !forecastContent) {
    return (
      <Box className={styles.wrapper} data-testid="subregion-forecaster-empty">
        <Box className={styles.surflineLogo}>
          <SurflineWaterDrop className={styles.waterDrop} />
        </Box>
        <Typography component="h2" variant="headline" className={styles.title}>
          <span data-testid="subregion-forecaster-name" />
          <br />
          <Typography component="span" variant="callout2" className={styles.subtitle}>
            {subregionOverview?.name} Surf Forecast
          </Typography>
        </Typography>
      </Box>
    );
  }

  return (
    <Box className={styles.wrapper} data-testid="subregion-forecaster">
      <Box className={styles.wrapperAnimate}>
        {forecasterImage}
        <Typography component="h2" variant="headline" className={styles.title}>
          {isNative ? (
            <span data-testid="subregion-forecaster-name">{forecastContent.forecaster.name}</span>
          ) : (
            <WavetrakLink href={forecasterUrl} data-testid="subregion-forecaster-name-link">
              {forecastContent.forecaster.name}
            </WavetrakLink>
          )}
          <br />
          <Typography component="span" variant="callout2" className={styles.subtitle}>
            {forecastContent.subregion.name} Surf Forecast
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export default SubregionForecaster;
