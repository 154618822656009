import React, { Fragment, RefObject, useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { Box, Skeleton } from '@mui/material';
import { uniqueId } from 'lodash';
import { useRouter } from 'next/router';
import { getWindow, trackEvent } from '@surfline/web-common';

import {
  NON_PREMIUM_FORECAST_DAYS_HOMEPAGE,
  PREMIUM_FORECAST_DAYS_HOMEPAGE,
} from 'common/constants';
import ErrorBoundary from 'components/ErrorBoundary';
import ErrorMessage from 'components/ErrorMessage';
import { PageContext } from 'contexts/PageContext';
import { useMaxWidthDesktopSmall } from 'hooks/useMediaQueries';
import { useUserPermissionStatus, useUserPreferredForecastView } from 'selectors/user';
import type { Height, SurfHeight } from 'types/units';
import type { DaySelectorOverview } from 'types/daySelector';
import createDateAtMidDay from 'utils/createDateAtMidDay';
import { spotReportPath } from 'utils/urls';

import ForecastDay from './components/ForecastDay';
import ForecastDayHeading from './components/ForecastDayHeading';
import ForecastDayTablePaywall from './components/ForecastDayTablePaywall';
import ForecastLabel from './components/ForecastLabel';
import SurfCurveChart from './components/SurfCurveChart';
import useForecastDates from './hooks/useForecastDates';

import styles from './ForecastDayTable.module.scss';

interface Props {
  forecasts?: DaySelectorOverview[];
  isCustomForecast?: boolean;
  isSubregionForecastActive?: boolean;
  paywallLocation?: PaywallLocation;
  scrollRef?: RefObject<HTMLDivElement>;
  units: { swellHeight?: Height | string; waveHeight?: SurfHeight | string };
}

export enum PaywallLocation {
  SHOW_TIMEZONE_SHIFT,
  HIDE_TIMEZONE_SHIFT,
}

const SIDEBAR_COLUMNS = 1;
const HEADING_ROWS = 2;
const NUMBER_OF_SKELETON_ROWS = 5;

const SKELETON_FORECASTS: DaySelectorOverview[] = Array.from(Array(NUMBER_OF_SKELETON_ROWS)).map(
  (_, index) => ({
    spotId: `${index}`,
    spotName: '',
    days: null,
    surf: null,
    loading: true,
  }),
);

const appendWavetrakAdIgnore = (className: string) => classNames(className, 'wavetrak-ad-ignore');

const DayTable: React.FC<Props> = ({
  forecasts = SKELETON_FORECASTS,
  isCustomForecast = false,
  isSubregionForecastActive = false,
  paywallLocation = PaywallLocation.HIDE_TIMEZONE_SHIFT,
  scrollRef,
  units,
}) => {
  const win = getWindow();
  const router = useRouter();
  const isMobileView = useMaxWidthDesktopSmall();
  const { forecastDates, uniqueDates, largestForecastLengthDays } = useForecastDates(
    forecasts || [],
    isMobileView ? NON_PREMIUM_FORECAST_DAYS_HOMEPAGE : PREMIUM_FORECAST_DAYS_HOMEPAGE,
  );
  const { pageName, pageId } = useContext(PageContext);
  const { hasCoreForecastPermissions } = useUserPermissionStatus();
  const preferredForecastView = useUserPreferredForecastView();

  const hidePaywall = useMemo(() => hasCoreForecastPermissions, [hasCoreForecastPermissions]);
  const isTableQuery = useMemo(() => router?.query?.view === 'table', [router?.query?.view]);
  const isTableViewMode = useMemo(
    () => preferredForecastView === 'TABLE' || isTableQuery,
    [isTableQuery, preferredForecastView],
  );

  const handleClickForecastDay = useCallback(
    (index: number, id: string, name: string | undefined, positionNumber: number) => {
      trackEvent('Forecast Outlook Clicked', {
        forecastStart: index,
        isCustomForecast,
        pageName,
        positionNumber,
        spotId: id,
        spotName: name,
      });
      const path = isCustomForecast
        ? `/custom-surf-forecast/favorite/${id}?initialStartDayIndex=${index}`
        : spotReportPath(
            {
              initialStartDayIndex: index,
              view: isTableViewMode ? 'table' : undefined,
            },
            {
              _id: id,
              name: name ?? '',
            },
          );
      if (win) win.location.href = path;
    },
    [isCustomForecast, isTableViewMode, pageName, win],
  );

  const handleClickPaywallCta = useCallback(() => {
    trackEvent('Clicked Subscribe CTA', {
      pageName,
      location: 'forecast outlook',
    });
  }, [pageName]);

  const useMetricUnits = useMemo(() => units?.waveHeight === 'M', [units]);

  const tableClasses = useMemo(
    () =>
      classNames({
        [styles.tableContainer]: true,
        [styles.metricUnits]: useMetricUnits,
      }),
    [useMetricUnits],
  );

  const headerDatesRowPanelClasses = useMemo(
    () =>
      classNames({
        [styles.headerDateRowPanel]: true,
        [styles.metricUnits]: useMetricUnits,
      }),
    [useMetricUnits],
  );

  const rowPanelClasses = useMemo(
    () =>
      classNames({
        [styles.rowPanel]: true,
        [styles.metricUnits]: useMetricUnits,
      }),
    [useMetricUnits],
  );

  const paywallClasses = useMemo(
    () =>
      classNames({
        [styles.paywall]: true,
        [styles.metricUnits]: useMetricUnits,
      }),
    [useMetricUnits],
  );

  const paywallColumnLocation = useMemo(
    () =>
      paywallLocation === PaywallLocation.SHOW_TIMEZONE_SHIFT
        ? uniqueDates.length
        : largestForecastLengthDays,
    [largestForecastLengthDays, paywallLocation, uniqueDates.length],
  );

  const paywallDays = useMemo(
    () =>
      uniqueDates.length && largestForecastLengthDays > 0
        ? paywallColumnLocation
        : NON_PREMIUM_FORECAST_DAYS_HOMEPAGE,
    [largestForecastLengthDays, paywallColumnLocation, uniqueDates.length],
  );

  const dateHeadings = useMemo(
    () =>
      forecastDates.map((headingDate, index) => (
        <div
          className={appendWavetrakAdIgnore(styles.dayHeadingWrapper)}
          key={`heading-${uniqueId()}`}
          data-testid="date-heading"
          style={{ gridColumn: index + SIDEBAR_COLUMNS + 1, gridRow: 1 }}
        >
          <ForecastDayHeading
            date={headingDate}
            isSubregionForecastActive={isSubregionForecastActive}
          />
        </div>
      )),
    [forecastDates, isSubregionForecastActive],
  );

  const spotRows = useMemo(
    () =>
      forecasts?.map((forecast, spotIndex) => {
        const gridRow = spotIndex + HEADING_ROWS + 1;
        const numberOfForecastDays = forecast.days?.length || 0;
        const forecastId = forecast?.spotId || '';
        const forecastUrl = isCustomForecast
          ? `/custom-surf-forecast/favorite/${forecastId}`
          : spotReportPath(
              {
                view: isTableViewMode ? 'table' : undefined,
              },
              {
                _id: forecastId,
                name: forecast?.spotName ?? '',
              },
            );

        return (
          <Fragment key={`forecast-${uniqueId()}`}>
            <div className={appendWavetrakAdIgnore(rowPanelClasses)} style={{ gridRow }} />
            <div
              className={appendWavetrakAdIgnore(styles.spotLabel)}
              style={{ gridRow, gridColumn: 1 }}
              data-testid="spot-row-label"
            >
              <ForecastLabel
                title={forecast?.spotName ?? ''}
                subtitle={forecast?.subtitle}
                url={forecastUrl}
              />
            </div>

            {forecast.loading && (
              <div
                className={appendWavetrakAdIgnore(styles.spotRowLoadingWrapper)}
                style={{ gridRow }}
              >
                <Skeleton
                  data-testid="forecast-day-table-loading-spot"
                  variant="rectangular"
                  className={styles.spotRowLoading}
                />
              </div>
            )}

            {forecast.error && (
              <div
                className={appendWavetrakAdIgnore(styles.spotRowErrorWrapper)}
                style={{ gridRow }}
                data-testid="forecast-day-table-spot-error"
              >
                <ErrorMessage />
              </div>
            )}

            {forecast.days?.map((dayContent, dayIndex) => {
              const gridColumn =
                forecastDates.findIndex(
                  (date) =>
                    dayContent.date &&
                    date &&
                    createDateAtMidDay(String(dayContent.date)).toISOString() ===
                      date.toISOString(),
                ) +
                SIDEBAR_COLUMNS +
                1;

              const handleClick = () =>
                handleClickForecastDay(dayIndex, forecastId, forecast.spotName, spotIndex);

              const forecastDayKey = `${
                isCustomForecast ? dayContent?.swell?.height : dayContent?.surf?.max
              }-${dayIndex + 1}`;

              return (
                <Fragment key={`forecast-day-${uniqueId()}`}>
                  {dayIndex === 0 && (forecast?.surf ?? forecast?.swell) && (
                    <div
                      className={classNames(
                        styles.surfCurveChartContainer,
                        `surf-chart-container-${forecast.spotId}`,
                      )}
                      style={{
                        gridRow,
                        gridColumnStart: gridColumn,
                        gridColumnEnd: gridColumn + numberOfForecastDays,
                      }}
                    >
                      <SurfCurveChart
                        chartIdPrefix={pageId || ''}
                        data={(isCustomForecast ? forecast.swell : forecast.surf) || []}
                        id={forecastId}
                        isCustomForecast={isCustomForecast}
                        units={
                          isCustomForecast
                            ? (units.swellHeight as Height)
                            : (units.waveHeight as SurfHeight)
                        }
                      />
                    </div>
                  )}
                  <div
                    key={`${forecast.spotName}_${dayContent.date}`}
                    className={classNames(styles.day, `graph-day-${forecast.spotId}`)}
                    style={{ gridRow, gridColumn }}
                  >
                    <ForecastDay
                      data={dayContent}
                      handleClick={handleClick}
                      isCustomForecast={isCustomForecast}
                      hasCoreForecastPermissions={hasCoreForecastPermissions}
                      key={forecastDayKey}
                      paywallColors={false}
                      units={units}
                    />
                  </div>
                </Fragment>
              );
            })}
          </Fragment>
        );
      }),
    [
      forecastDates,
      forecasts,
      handleClickForecastDay,
      isCustomForecast,
      isTableViewMode,
      pageId,
      rowPanelClasses,
      hasCoreForecastPermissions,
      units,
    ],
  );

  return (
    <div className={styles.container} data-testid="forecast-day-table">
      <div
        ref={scrollRef}
        className={tableClasses}
        style={{
          // @ts-ignore
          '--number-of-columns': forecastDates.length,
          '--paywall-days': paywallDays,
          '--number-of-forecasts': forecasts?.length || 0,
          '--sidebar-columns': SIDEBAR_COLUMNS,
          '--heading-rows': HEADING_ROWS,
        }}
        data-testid="forecast-day-table-grid"
      >
        <div className={appendWavetrakAdIgnore(headerDatesRowPanelClasses)} />
        {dateHeadings}
        {spotRows}
        {!hidePaywall && (
          <div
            className={appendWavetrakAdIgnore(paywallClasses)}
            data-testid="forecast-day-table-paywall-wrapper"
          >
            <ForecastDayTablePaywall
              showSmallPaywallDesktop={forecasts.length < 3}
              onClickCta={() => handleClickPaywallCta()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

interface ForecastDayTableProps extends Props {
  error?: string | boolean;
}

const ForecastDayTable: React.FC<ForecastDayTableProps> = (props) => {
  const { error } = props;

  return (
    <ErrorBoundary
      error={error}
      errorBoundaryId="forecast-day-table"
      render={() => (
        <Box data-testid="forecast-day-table-full-error">
          <ErrorMessage />
        </Box>
      )}
    >
      <DayTable {...props} />
    </ErrorBoundary>
  );
};

export default ForecastDayTable;
