import React, { useMemo, type FunctionComponent } from 'react';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

import styles from './FavoriteRegionFilters.module.scss';

interface FavoriteRegionFiltersProps {
  exclusive?: boolean;
  favoriteRegions: Array<{ id: string; name: string; forecastStatus: 'active' | 'inactive' }>;
  handleRegionSelected: (
    event: React.MouseEvent<HTMLElement>,
    newRegions: Array<string> | string,
  ) => void;
  selectedRegions: Array<string> | string | undefined;
}

const FavoriteRegionFilters: FunctionComponent<FavoriteRegionFiltersProps> = ({
  exclusive = false,
  favoriteRegions,
  handleRegionSelected,
  selectedRegions,
}) => {
  const regionToggles = useMemo(
    () =>
      favoriteRegions?.map((region) => (
        <ToggleButton
          data-testid="favorites-region-selector-toggles"
          disableFocusRipple
          disableRipple
          disableTouchRipple
          key={region.name}
          value={region.id}
          disabled={exclusive === true && selectedRegions === region.id}
        >
          <Typography variant="body2">{region.name}</Typography>
        </ToggleButton>
      )),
    [exclusive, favoriteRegions, selectedRegions],
  );

  if (!favoriteRegions.length) return null;

  return (
    <div className={styles.scrollContainer} data-testid="regions-selector-scroll-container">
      <div className={styles.regionSelectorContainer}>
        <ToggleButtonGroup
          value={selectedRegions}
          onChange={handleRegionSelected}
          className={styles.regionSelector}
          exclusive={exclusive}
        >
          {regionToggles}
        </ToggleButtonGroup>
      </div>
    </div>
  );
};

export default FavoriteRegionFilters;
