import { type FunctionComponent, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { utcToZonedTime } from 'date-fns-tz';
import { useRouter } from 'next/router';
import { Box, Button, Card, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { trackEvent } from '@surfline/web-common';

import Information from 'components/Information';
import { SubregionForecastContentPaywallDialogFooter } from 'components/SubregionForecastPaywalls';
import { PRODUCT_TUTORIALS } from 'common/constants';
import PadlockIcon from 'components/Icons/Padlock';
import ForecasterProfile from 'components/ForecasterProfile';
import SubregionForecastLink from 'components/SubregionForecastLink';
import { useUserPermissionStatus } from 'selectors/user';
import type { ForecastContent } from 'types/subregion';

import styles from './SubregionForecastContentNow.module.scss';

interface Props {
  className?: string;
  elevation?: number;
  forecastContent?: ForecastContent;
  forecastNote?: string | null;
  isLoading?: boolean;
  isTitleOnly?: boolean;
}

const SubregionForecastContentNow: FunctionComponent<Props> = ({
  className,
  elevation,
  forecastContent,
  forecastNote,
  isLoading,
  isTitleOnly = false,
}) => {
  const { query } = useRouter();
  const { hasCoreForecastPermissions } = useUserPermissionStatus();

  const isNative = query?.native?.toString() === 'true';
  const upgradeUrl = isNative ? 'surfline://upgrade' : '/upgrade';

  const isAfter2pm = useMemo(() => {
    const forecastTimezone = forecastContent?.timezone;
    if (!forecastTimezone) return false;
    const nowInForecastTimezone = utcToZonedTime(new Date(), forecastTimezone);
    const after2pm = nowInForecastTimezone.getHours() >= 14;
    return after2pm;
  }, [forecastContent]);

  const segmentPropsLock = useMemo(
    () => ({
      location: 'Forecast Content Lock',
      modalType: 'paywall',
      subregionId: forecastContent?.subregion.id,
      subregionName: forecastContent?.subregion.name,
    }),
    [forecastContent],
  );

  const onClickHandler = useCallback(() => {
    trackEvent('Clicked CTA', { ...segmentPropsLock });
  }, [segmentPropsLock]);

  if (isLoading)
    return (
      <Skeleton
        animation="wave"
        className={styles.loadingNow}
        data-testid="subregion-forecast-content-now-loading"
        variant="rectangular"
      />
    );

  if (!forecastContent) return null;

  return (
    <Card
      className={classNames(styles.forecasterContentNow, className)}
      component="section"
      elevation={elevation}
      data-testid="subregion-forecast-content-now"
    >
      <Box padding={1}>
        <Stack component="header" direction="row" mb={2}>
          <Box sx={{ width: '100%' }}>
            <ForecasterProfile
              conditions=""
              iconUrl={forecastContent.forecaster.avatarUrl}
              isShowConditionsBorder={false}
              isShowName
              lastUpdate={{ timestamp: forecastContent.lastPublished }}
              name={forecastContent.forecaster.name}
              title={forecastContent.tomorrowIsLive ? 'Tomorrow' : 'Today'}
            />
          </Box>
          <Box>
            <SubregionForecastLink
              start={forecastContent.tomorrowIsLive ? 'forecast-tomorrow' : 'forecast-today'}
              subregionId={forecastContent.subregion.id}
              subregionName={forecastContent.subregion.name}
            />
          </Box>
        </Stack>
        {!hasCoreForecastPermissions && (
          <>
            {!isAfter2pm && !forecastContent.tomorrowIsLive && (
              <Typography component="h5" variant="body" mb={2}>
                Today&apos;s Forecast will be available to you at 2pm.
              </Typography>
            )}
            <Stack
              direction="row"
              spacing={1}
              mt={2}
              mb={isAfter2pm || forecastContent.tomorrowIsLive ? 2 : 0}
            >
              <Information
                DialogFooter={
                  <SubregionForecastContentPaywallDialogFooter
                    segmentProps={segmentPropsLock}
                    upgradeUrl={upgradeUrl}
                  />
                }
                articleId={PRODUCT_TUTORIALS.articles.paywallForecasterContent.id}
                theme="cinematic"
              >
                <IconButton
                  aria-label="Premium content is locked until 2pm. Upgrade to see it now."
                  className={classNames({
                    [styles.paywallLockButton]: true,
                    [styles.paywallLockButtonUnlocked]:
                      isAfter2pm && !forecastContent.tomorrowIsLive,
                  })}
                  data-testid="subregion-forecast-content-now-paywall-button"
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  onClick={onClickHandler}
                >
                  <PadlockIcon locked={!isAfter2pm || forecastContent.tomorrowIsLive} />
                </IconButton>
              </Information>
              <Button
                data-testid="subregion-forecast-content-now-upgrade-button"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                href={upgradeUrl}
                onClick={onClickHandler}
                variant="primary"
              >
                {isAfter2pm && !forecastContent.tomorrowIsLive
                  ? 'Get it earlier with Premium'
                  : 'Upgrade to see it now'}
              </Button>
            </Stack>
          </>
        )}
        {(hasCoreForecastPermissions || (isAfter2pm && !forecastContent.tomorrowIsLive)) && (
          <>
            <Typography component="h5" variant="body" mb={2}>
              {forecastContent.tomorrowIsLive
                ? forecastContent.tomorrow.forecast.headline
                : forecastContent.today.forecast.headline}
            </Typography>
            {!isTitleOnly && (
              <Typography component="p" variant="body1">
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: forecastContent.tomorrowIsLive
                      ? forecastContent.tomorrow.forecast.observation
                      : forecastContent.today.forecast.observation,
                  }}
                />
              </Typography>
            )}
            {!isTitleOnly && forecastNote && (
              <Typography component="div" variant="body1">
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: forecastNote }} />
              </Typography>
            )}
          </>
        )}
      </Box>
    </Card>
  );
};

export default SubregionForecastContentNow;
