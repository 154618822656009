import React, { type FunctionComponent } from 'react';
import { format } from 'date-fns';
import { format as formatTz, utcToZonedTime } from 'date-fns-tz';
import { pageStructuredData } from '@surfline/web-common';

import MetaTags from 'components/MetaTags';
import config from 'config';
import { oneSecond } from 'utils/time';
import { subregionForecastPath } from 'utils/urls';

const title = (subregionName: string, timestamp: number | null) =>
  `Premium ${subregionName} Weather Forecast for Storms, Swell & Surf in ${format(
    timestamp ? new Date(timestamp * oneSecond) : new Date(),
    'MMMM yyyy',
  )} - Surfline`;

const description = (subregionName: string) =>
  `The most accurate and trusted premium ${subregionName} surf reports, forecasts, and coastal weather. Surfers from around the world choose Surfline for daily forecasts and timely news`.replace(
    '  ',
    ' ',
  );

const formattedDate = (timestamp: number | null, timezone: string | null) => {
  if (!timestamp || !timezone) return undefined;
  const zonedDate = utcToZonedTime(timestamp * oneSecond, timezone);
  return formatTz(zonedDate, 'MM/dd/yyyy', { timeZone: timezone });
};

interface Props {
  subregionId: string;
  subregionName: string;
  timestamp?: number;
  timezone?: string;
  headline?: string;
  author?: string;
}

const PremiumAnalysisMeta: FunctionComponent<Props> = ({
  subregionId,
  subregionName,
  timestamp = null,
  timezone = null,
  headline = null,
  author = null,
}) => (
  <MetaTags
    title={title(subregionName, timestamp)}
    description={description(subregionName)}
    urlPath={subregionForecastPath({ subregionId, subregionName })}
    parentUrl={subregionForecastPath({ subregionId, subregionName })}
    parentTitle={title(subregionName, timestamp)}
  >
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: pageStructuredData({
          url: `${config.surflineHost}${subregionForecastPath({ subregionId, subregionName })}`,
          headline: headline || title(subregionName, timestamp),
          dateModified: formattedDate(timestamp, timezone),
          author: author || 'Surfline Forecast Team',
          description: description(subregionName),
          cssSelector: '.sl-premium-analysis',
        }),
      }}
    />
  </MetaTags>
);

export default PremiumAnalysisMeta;
