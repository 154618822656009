import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowScroll } from 'react-use';
import classNames from 'classnames';
import { isNumber } from 'lodash';
import { Box, Button, Stack, SwipeableDrawer, Typography } from '@mui/material';
import { trackEvent } from '@surfline/web-common';
import withContentCards from 'components/withContentCards';
import { toggleCtaDrawer } from 'actions/cta';
import { useMinWidthTablet } from 'hooks/useMediaQueries';
import { getCtaDrawer } from 'selectors/cta';
import { getSpotDetails, getSpotReportAssociated, getSpotReportForecast } from 'selectors/spot';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import conditionIsFlat from 'utils/conditionIsFlat';
import CurrentWaveHeightAndRating, { Size } from 'components/CurrentWaveHeightAndRating';
import { CTACheckmarkIcon, Chevron } from 'components/Icons';
import PremiumChip from 'components/PremiumChip';
import WavetrakLink from 'components/WavetrakLink';
import Styles from './PremiumUpgradePillCTAV2.module.scss';

interface BrazeCard {
  name: string;
  extras: {
    box__body: string;
    box__title: string;
    box__link: string;
  } | null;
}

type Props = {
  card: BrazeCard;
  // eslint-disable-next-line react/no-unused-prop-types
  defaultCard: Pick<BrazeCard, 'extras'>;
  segmentProperties?: {
    location: 'Premium Pill Latest' | 'Premium Pill Forecast';
  };
  dataTest?: string;
};

const SCROLL_OFFSET = 500;

const PREMIUM_BENEFITS = [
  'Every cam, ad-free.',
  'Daily surf reports.',
  'Extended 16-day forecasts.',
  'Expert forecaster analysis.',
];

const getCtaWrapClasses = (scrollOffsetReached: boolean) =>
  classNames(Styles.ctaWrap, {
    [Styles['ctaWrap-scrolled']]: scrollOffsetReached,
  });

const getChevronClasses = (scrollOffsetReached: boolean) =>
  classNames(Styles.chevron, {
    [Styles['chevron-scrolled']]: scrollOffsetReached,
  });

export const PremiumUpgradePillCTAV2: React.FC<Props> = ({
  card,
  segmentProperties,
  dataTest = 'premium-upgrade-pill-cta',
}) => {
  const dispatch = useAppDispatch();
  const [scrollOffsetReached, setScrollOffsetReached] = useState(false);
  const { open, type } = useAppSelector(getCtaDrawer);
  const spotDetails = useAppSelector(getSpotDetails);
  const spotForecast = useAppSelector(getSpotReportForecast);
  const spotAssociated = useAppSelector(getSpotReportAssociated);
  const isDesktop = useMinWidthTablet();
  const windowScroll = useWindowScroll();
  const toggleDrawerCallback = useCallback(() => dispatch(toggleCtaDrawer()), [dispatch]);
  const onClickButtonHandler = useCallback((event) => {
    event.stopPropagation();
    trackEvent('Clicked Subscribe CTA', segmentProperties);
  }, []);
  const displayCamContent = useMemo(
    () => type === 'cam' && spotDetails?.cameras?.length,
    [spotDetails, type],
  );
  const camStillImage = useMemo(() => `url('${spotDetails?.cameras[0]?.stillUrl}')`, [spotDetails]);
  useEffect(() => {
    if (windowScroll?.y > SCROLL_OFFSET !== scrollOffsetReached) {
      setScrollOffsetReached(!scrollOffsetReached);
    }
  }, [scrollOffsetReached, windowScroll?.y]);
  const isFlat =
    isNumber(spotForecast?.waveHeight?.max) && spotAssociated?.units?.waveHeight
      ? conditionIsFlat(spotForecast?.waveHeight?.max, spotAssociated?.units?.waveHeight)
      : false;
  return (
    <>
      <Box
        className={getCtaWrapClasses(scrollOffsetReached)}
        data-testid={dataTest}
        onClick={toggleDrawerCallback}
      >
        <Stack className={Styles.ctaInner}>
          <Stack className={Styles.ctaContent} direction="row">
            <Stack>
              <div className={getChevronClasses(scrollOffsetReached)}>
                <Chevron direction="up" />
              </div>
              <Typography
                whiteSpace="nowrap"
                className={Styles.ctaText}
                variant={isDesktop ? 'h3' : 'subHeadline'}
                color={scrollOffsetReached ? 'common.white' : 'text.brandSecondary'}
              >
                {card?.extras?.box__title || 'Get more with Premium'}
              </Typography>
            </Stack>
            <WavetrakLink
              href={card?.extras?.box__link || '/upgrade'}
              isExternal
              onClick={onClickButtonHandler}
            >
              <Button
                className={Styles.ctaButton}
                color={scrollOffsetReached ? 'dark' : 'primary'}
                disableFocusRipple
                disableRipple
                disableTouchRipple
                size={isDesktop ? 'medium' : 'small'}
                variant="primary"
              >
                {card?.extras?.box__body || 'Start free trial'}
              </Button>
            </WavetrakLink>
          </Stack>
        </Stack>
      </Box>
      <SwipeableDrawer
        anchor="bottom"
        className={Styles.drawer}
        open={open}
        onClose={toggleDrawerCallback}
        onOpen={toggleDrawerCallback}
        disableSwipeToOpen={false}
      >
        <Stack className={Styles.drawerContainer} direction="row" data-testid="cta-drawer">
          <Stack className={Styles.drawerContentContainer}>
            <Box className={Styles.drawerContent}>
              <Box className={Styles.chevron} onClick={toggleDrawerCallback}>
                <Chevron direction="down" />
              </Box>
              <PremiumChip />
              <Typography variant="title2" className={Styles.drawerTitle}>
                {displayCamContent
                  ? 'Try Surfline Premium and get unlimited access to:'
                  : 'Get 16-day forecasts with Premium.'}
              </Typography>
              <ul className={Styles.drawerList}>
                {PREMIUM_BENEFITS.map((item) => (
                  <li key={item}>
                    <CTACheckmarkIcon />
                    <Typography>{item}</Typography>
                  </li>
                ))}
              </ul>
              <WavetrakLink href="/upgrade" isExternal onClick={onClickButtonHandler}>
                <Button
                  className={Styles.drawerButton}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  size="large"
                  variant="primary"
                >
                  Start free trial
                </Button>
              </WavetrakLink>
            </Box>
          </Stack>
          {displayCamContent ? (
            <Stack className={Styles.camMediaContainer}>
              <Box
                className={Styles.camMedia}
                style={{ backgroundImage: `url('${camStillImage}')` }}
              >
                <Box className={Styles.spotDetails}>
                  <Typography variant="title3" className={Styles.spotName}>
                    {spotDetails.name}
                  </Typography>
                  <CurrentWaveHeightAndRating
                    size={Size.SMALL}
                    conditions={spotForecast?.conditions?.value}
                    isFlat={isFlat}
                    units={spotAssociated?.units}
                    waveHeight={spotForecast?.waveHeight}
                  />
                </Box>
              </Box>
            </Stack>
          ) : (
            <Stack className={Styles.forecastMediaContainer} data-testid="cta-drawer-fcst">
              <Box className={Styles.forecastMedia} />
            </Stack>
          )}
        </Stack>
      </SwipeableDrawer>
    </>
  );
};

export default memo(withContentCards(PremiumUpgradePillCTAV2 as React.ComponentType<{}>));
