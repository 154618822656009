import config from 'config';
import axios, { AxiosResponse } from 'axios';

const TIMEOUT_IN_MS = 30000;

const createClip = async (
  cameraId: string,
  startTimestampInMs: number,
  endTimestampInMs: number,
  accessToken: string,
): Promise<AxiosResponse> =>
  axios({
    method: 'POST',
    url: `${config.servicesURL}/cameras/${cameraId}/clip${
      accessToken ? `?accessToken=${accessToken}` : ''
    }`,
    data: {
      startTimestampInMs,
      endTimestampInMs,
    },
    timeout: TIMEOUT_IN_MS,
  });

export default createClip;
