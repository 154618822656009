import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  type TooltipProps,
  Typography,
} from '@mui/material';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import useSWR from 'swr';
import { slugify, trackEvent } from '@surfline/web-common';
import { isEmpty } from 'lodash';
import { DismissIcon } from '@wavetrak/theme';

import CloudflareResizedImage from 'components/CloudflareResizedImage';
import { InfoIcon } from 'components/Icons';
import ErrorMessage from 'components/ErrorMessage';
import PageLoading from 'components/PageLoading';
import { useMaxWidthTablet } from 'hooks/useMediaQueries';
import type { ContentfulMediaField } from 'types/contentful/fields';
import getTutorialArticlesQuery from 'common/api/contentful/queries/getTutorialArticlesQuery';
import getArticleQuery from 'common/api/contentful/queries/getArticleQuery';
import contentfulFetch from 'common/api/contentful/contentfulFetch';

import styles from './Information.module.scss';

export interface SegmentProperties {
  forecastPeriod?: number;
  graphType?: string;
  modalType: string;
  spotId?: string;
  spotName?: string;
  subregionId?: string;
  subregionName?: string;
  tabName?: string;
  uiElement?: string;
}

type OptionalProps = {
  DialogFooter?: React.ReactNode;
  DialogSubHeader?: React.ReactNode;
  articleId?: string; // Contentful Content ID for specific Tutorial Article to show
  children?: ReactElement;
  className?: string;
  'data-testid'?: string;
  dialogClassName?: string;
  isError?: boolean | string;
  isNative?: boolean;
  segmentProps?: SegmentProperties;
  theme?: 'default' | 'cinematic';
  tooltip?: string | (Partial<TooltipProps> & Pick<TooltipProps, 'title'>);
  tutorialId?: string; // Contentful Content ID for Product Tutorial Group
};

export type InformationProps = OptionalProps &
  (
    | { articleId: string }
    | { tutorialId: string; articleId?: string }
    | {
        tooltip: string | (Partial<TooltipProps> & Pick<TooltipProps, 'title'>);
        tutorialId?: string;
        articleId?: string;
      }
  );

export interface Block {
  fileName: string;
  title: string;
  description: string;
  url: string;
  sys: {
    id: string;
  };
}

export interface Item {
  sys: {
    id: string;
  };
  title: string;
  topic: string;
  body: {
    json: Document | JSON | any;
    links: {
      assets: {
        block: Block[];
      };
    };
  };
}

export const getMediaTypeFromFilename = (filename: string): string | undefined => {
  const extension = filename.split('.').pop();
  switch (extension?.toLowerCase()) {
    case 'mp4':
      return 'video';
    case 'jpg':
    case 'jpeg':
    case 'gif':
      return 'image';
    default:
      return undefined;
  }
};

export const Information = ({
  DialogFooter = null,
  DialogSubHeader = null,
  articleId,
  children,
  className,
  'data-testid': dataTestId,
  dialogClassName,
  isError,
  isNative,
  segmentProps,
  theme,
  tooltip,
  tutorialId,
}: InformationProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const activeButtonRef = useRef(null) as any;

  const fetchQuery: null | string = useMemo(() => {
    if (tutorialId && isModalOpen) return getTutorialArticlesQuery(tutorialId);
    if (articleId && isModalOpen) return getArticleQuery(articleId);
    return null;
  }, [articleId, isModalOpen, tutorialId]);

  const { data, isLoading, error } = useSWR(() => fetchQuery, contentfulFetch, {
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    refreshInterval: 0,
  });

  const items = useMemo(
    () =>
      articleId && !tutorialId
        ? [data?.data?.tutorialArticle]
        : data?.data?.tutorial?.articlesCollection?.items,
    [
      articleId,
      tutorialId,
      data?.data?.tutorialArticle,
      data?.data?.tutorial?.articlesCollection?.items,
    ],
  );

  const size = data?.data?.tutorial?.size;
  const isMobileView = useMaxWidthTablet();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      if (!isEmpty(segmentProps)) {
        trackEvent('Modal Opened', segmentProps);
      }
      setIsModalOpen(true);
    },
    [segmentProps],
  );

  const handleClose = useCallback((event: React.MouseEvent<HTMLOrSVGElement>) => {
    event.stopPropagation();
    setIsModalOpen(false);
  }, []);

  const assetBlockMap = useMemo(() => {
    const blocks = items?.flatMap((item: Item) => item?.body?.links?.assets?.block);
    return new Map(blocks?.map((block: Block) => [block?.sys?.id, block]));
  }, [items]);

  const renderHeading = (_: any, childrenToRender: any) => (
    <Typography component="h3" variant="title3" mb={2}>
      {childrenToRender}
    </Typography>
  );

  const renderParagraph = (_: any, childrenToRender: any) => (
    <Typography component="p" variant="body1" mb={4}>
      {childrenToRender}
    </Typography>
  );

  const handleTabClick = useCallback((index: number, topic: string) => {
    const tabName = slugify(topic);
    trackEvent('Modal Opened', { modalType: 'tutorial', uiElement: tabName });
    setActiveTab(index);
  }, []);

  const RICHTEXT_OPTIONS = useMemo(
    () => ({
      renderNode: {
        [BLOCKS.HEADING_3]: renderHeading,
        [BLOCKS.PARAGRAPH]: renderParagraph,
        // eslint-disable-next-line react/no-unstable-nested-components
        [BLOCKS.EMBEDDED_ASSET]: ({ data: assetData }: any) => {
          const media = assetBlockMap.get(
            assetData?.target?.sys?.id,
          ) as unknown as ContentfulMediaField;
          if (!media) {
            return null;
          }
          const mediaType = getMediaTypeFromFilename(media?.fileName);
          if (mediaType === 'image') {
            return (
              <div className={classNames(styles.image, 'sl-info-dialog-image')}>
                <CloudflareResizedImage
                  src={media?.url}
                  alt={media?.title}
                  width={media.width || undefined}
                  height={media.height || undefined}
                  loading="lazy"
                  sizes="(min-width: 450px) 45vw, (max-width: 768px) 100vw"
                />
              </div>
            );
          }
          if (mediaType === 'video') {
            return (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video
                autoPlay
                className={styles.video}
                controls={theme === 'cinematic' ? true : undefined}
                data-testid="information-dialog-video"
                loop={theme !== 'cinematic'}
                muted={theme !== 'cinematic'}
                playsInline
                src={media.url}
                title={media.title}
              />
            );
          }
          return null;
        },
      },
    }),
    [assetBlockMap, theme],
  );

  const tabs = useMemo(
    () => (
      <div>
        {items?.length > 1 && (
          <ul className={styles.unorderedList}>
            {items?.map((item: Item, i: number) => (
              <li key={item?.sys?.id} className={styles.listItems}>
                <Button
                  ref={i === activeTab ? activeButtonRef : null}
                  className={`${styles.button} ${i === activeTab ? styles.buttonActive : ''}`}
                  disableRipple
                  disableTouchRipple
                  onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                    event.stopPropagation();
                    handleTabClick(i, item?.topic);
                  }}
                >
                  {item?.topic}
                </Button>
              </li>
            ))}
          </ul>
        )}
      </div>
    ),
    [activeTab, handleTabClick, items],
  );

  const testId = useMemo(() => dataTestId || 'information', [dataTestId]);

  useEffect(() => {
    if (activeButtonRef.current && activeButtonRef.current.scrollIntoView) {
      activeButtonRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'end',
      });
    }
  }, [activeTab]);

  useEffect(() => {
    const activeIndex = items?.findIndex((item: Item) => item?.sys?.id === articleId);
    setActiveTab(activeIndex >= 0 ? activeIndex : 0);
  }, [articleId, items]);

  return (
    <Box className={className} data-testid={testId} display="inline" position="relative">
      <Tooltip
        arrow
        className={styles.tooltip}
        disableTouchListener={isMobileView}
        enterTouchDelay={0}
        placement="top"
        {...(typeof tooltip === 'string'
          ? { title: tooltip }
          : { title: tooltip?.title, ...tooltip })}
      >
        <div>
          {children ? (
            React.Children.map(children, (child) =>
              React.cloneElement(child, {
                onClick: handleClick,
              }),
            )
          ) : (
            <IconButton
              disableFocusRipple
              disableRipple
              disableTouchRipple
              onClick={handleClick}
              data-testid="information-icon-button"
            >
              <InfoIcon />
            </IconButton>
          )}
        </div>
      </Tooltip>
      {(articleId || tutorialId) && (
        <Dialog
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          data-testid="information-dialog-modal"
          className={classNames(
            {
              [styles.dialog]: true,
              [styles.dialogSmall]: size === 'small',
              [styles.dialogCinematic]: theme === 'cinematic',
              [styles.dialogNative]: isNative,
            },
            dialogClassName,
          )}
          onClose={handleClose}
          open={isModalOpen}
          fullScreen={isMobileView}
          scroll="paper"
          classes={{ paper: styles.dialogPaper }}
          PaperProps={theme === 'cinematic' ? { elevation: 0 } : {}}
        >
          <DialogTitle>
            <div className={classNames(styles.dialogCloseWrapper, 'sl-info-dialog-close-wrapper')}>
              <IconButton
                className={classNames(styles.dialogClose, 'sl-info-dialog-close')}
                data-testid="information-dialog-modal-close-button"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={handleClose}
              >
                <DismissIcon />
              </IconButton>
            </div>
            <Box data-testid="information-tabs">{tabs}</Box>
          </DialogTitle>
          <DialogContent data-testid="information-content">
            {(error || isError) && (
              <div className={styles.errorMessageContainer}>
                <ErrorMessage className={styles.errorMessage} />
              </div>
            )}

            {isLoading && (
              <div className={styles.loading} data-testid="information-loading">
                <PageLoading />
              </div>
            )}
            {!isLoading && (!error || !isError) && (
              <DialogContentText
                component="div"
                className={styles.dialogContent}
                data-testid="information-content-text"
              >
                <div className={styles.dialogContentText}>
                  <Typography className={styles.title} component="h3" variant="headline">
                    {items?.[activeTab]?.title}
                  </Typography>
                  {DialogSubHeader}
                  <div className={styles.body}>
                    {documentToReactComponents(items?.[activeTab]?.body?.json, RICHTEXT_OPTIONS)}
                  </div>
                </div>
              </DialogContentText>
            )}
          </DialogContent>
          {DialogFooter && <DialogActions>{DialogFooter}</DialogActions>}
        </Dialog>
      )}
    </Box>
  );
};

export default memo(Information);
