import { useEffect, useMemo, useState, useContext, useCallback } from 'react';
import cx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContextualRouting } from 'next-use-contextual-routing';
import { useMount } from 'react-use';
import useSWR from 'swr';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { createParamString, trackEvent, type Units, getUser } from '@surfline/web-common';

import { fetchFavoriteSubregions, fetchForecasterContent } from 'actions/subregion';
import { BRAZE_CONTENT_CARDS, NON_PREMIUM_FORECAST_DAYS_HOMEPAGE } from 'common/constants';
import { usePageCall } from 'common/hooks/usePageCall';
import {
  SL_WEB_PREMIUMPLUS_LIMITED_TIME_ACCESS,
  SL_WEB_FORECASTER_CONTENT,
  SL_WEB_REWIND_RECAPS,
} from 'common/treatments';
import AnonHero from 'components/AnonHero';
import { PLAYED_WEBCAM } from 'components/CamPlayerV2/useTrackCamPlayback';
import ContentCard from 'components/ContentCard';
import EditorialCarousel from 'components/EditorialCarousel';
import EventsAndAlerts from 'components/EventsAndAlerts';
import FavoritesCarousel from 'components/FavoritesCarousel';
import FavoritesCarouselSnap from 'components/FavoritesCarouselSnap';
import FavoriteRegionFilters from 'components/FavoriteRegionFilters';
import GoogleDFP from 'components/GoogleDFP';
import HomePageMeta from 'components/HomePageMeta';
import LimitedTimeOfferBanner from 'components/LimitedTimeOfferBanner/LimitedTimeOfferBanner';
import ModalContextual from 'components/ModalContextual';
import SwellEventCardContainer from 'components/SwellEventCardContainer/SwellEventCardContainer';
import SubregionForecastContentGrouped from 'components/SubregionForecastContentGrouped';
import SubregionForecastContentHighlights from 'components/SubregionForecastContentHighlights';
import SubregionForecastContentNow from 'components/SubregionForecastContentNow';
import Favorites from 'containers/Favorites';
import FavoritesForecastOutlook from 'containers/FavoritesForecastOutlook/FavoritesForecastOutlook';
import RegionalRewindRecapsPoc from 'containers/RegionalRewindRecapsPoc';
import SubregionForecast from 'containers/SubregionForecast';
import { ForecastOutlookContextProvider } from 'contexts/ForecastOutlookContext';
import { PageContextProvider } from 'contexts/PageContext';
import { UserTypeContext } from 'contexts/UserTypeContext';
import { useMaxWidthTablet, useMinWidthTablet } from 'hooks/useMediaQueries';
import useFavorites, { type Region } from 'hooks/useFavorites';
import { getForecastContent, getForecastContentLoading } from 'selectors/subregion';
import { useUserPermissionStatus } from 'selectors/user';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import type { Units as LocalUnits } from 'types/units';
import type { ForecastContent } from 'types/subregion';
import type { SwellEvent } from 'types/swellEvents';
import loadAdConfig from 'utils/adConfig';
import computeLimitedTimePremiumPlusEligibility from 'utils/computeLimitedTimePremiumPlusEligibility';
import { getPopularSpots } from 'utils/favoritesFetch';
import getGreeting from 'utils/greeting';
import swellEventsFetch from 'utils/swellEventsFetch';
import { useTreatments, parseTreatmentWithConfig } from 'utils/treatments';

import styles from './Homepage.module.scss';

interface HomepageProps {
  geotarget: string;
  colorSchemeIndex?: number;
  userCountryCode: string;
  units: Units;
}

const MODAL_OPEN_PATHS = ['/favorites'];

const onClickSwellEventCard = (swellEvent: SwellEvent) => {
  trackEvent('Clicked Swell Alert Card', {
    title: swellEvent.name,
    contentType: 'Swell Alert',
    basins: swellEvent.basins.join(','),
    locationCategory: 'Homepage - Top',
    destinationUrl: swellEvent.permalink,
  });
};

const Homepage: React.FC<HomepageProps> = ({
  geotarget,
  userCountryCode,
  colorSchemeIndex,
  units,
}) => {
  const router = useRouter();
  const { makeContextualHref, returnHref } = useContextualRouting();
  const { userType, entitlements, showAds } = useContext(UserTypeContext);
  const isMaxWidthTablet = useMaxWidthTablet();
  const { hasCoreForecastPermissions } = useUserPermissionStatus();
  const treatments = useTreatments();
  const user = useAppSelector(getUser);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { favorites, regions } = useFavorites({ allRegions: false });
  const [selectedRegion, setSelectedRegion] = useState<Region | undefined>(regions?.[0]);
  const isMinWidthTablet = useMinWidthTablet();

  const forecastContent: ForecastContent = useAppSelector(getForecastContent);
  const forecastContentLoading: boolean = useAppSelector(getForecastContentLoading);

  const { asPath, query } = router;
  const { subregionForecast } = query;
  const userLocation = user?.location;
  const userTimeZone = userLocation?.time_zone || 'America/Los_Angeles';
  const viewAllLabel = isMaxWidthTablet ? 'View all' : 'View all favorites';
  const isForecasterContentOn = treatments?.[SL_WEB_FORECASTER_CONTENT] === 'on';
  const isSubregionForecastActive = selectedRegion?.forecastStatus === 'active';

  const { data: swellEvents } = useSWR(
    `/feed/events?${createParamString({ geotarget })}`,
    swellEventsFetch,
  );

  const { data: popularSpots, error: popularSpotsError } = useSWR(
    userType?.isAnonymous ? `/kbyg/spots/popular?` : undefined,
    () => getPopularSpots(userCountryCode),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryCount: 0,
    },
  );

  const filteredSubregionFavorites = useMemo(
    () => favorites.filter((fav) => fav.subregionId === selectedRegion?.id),
    [favorites, selectedRegion],
  );

  const forecastOutlookFavorites = useMemo(() => {
    if (userType?.isAnonymous) return popularSpots?.data.favorites ?? [];
    if (isForecasterContentOn) return filteredSubregionFavorites;
    return favorites;
  }, [favorites, filteredSubregionFavorites, isForecasterContentOn, popularSpots, userType]);

  const limitedTimePremiumPlusConfig = useMemo(
    () =>
      treatments?.[SL_WEB_PREMIUMPLUS_LIMITED_TIME_ACCESS]
        ? parseTreatmentWithConfig(treatments[SL_WEB_PREMIUMPLUS_LIMITED_TIME_ACCESS])
        : null,
    [treatments],
  );

  const isLimitedTimePremiumPlusEligible = useMemo(() => {
    if (limitedTimePremiumPlusConfig && treatments?.[SL_WEB_PREMIUMPLUS_LIMITED_TIME_ACCESS]) {
      return computeLimitedTimePremiumPlusEligibility(limitedTimePremiumPlusConfig, userTimeZone);
    }
    return false;
  }, [limitedTimePremiumPlusConfig, treatments, userTimeZone]);

  const adTarget = useMemo(() => [['qaFlag', !!query?.qaFlag]], [query?.qaFlag]);

  const greeting = useMemo(
    () =>
      user?.details?.firstName
        ? `${getGreeting(new Date())}, ${user.details.firstName}!`
        : `${getGreeting(new Date())}!`,
    [user],
  );

  const contextualHref = useMemo(() => makeContextualHref({ type: 'live' }), [makeContextualHref]);

  const segmentProperties = useMemo(
    () => ({
      userType,
      category: 'home',
      channel: 'home',
      subCategory: 'personalized',
    }),
    [userType],
  );

  usePageCall('Home', segmentProperties, !!router);

  const handleRegionSelected = useCallback(
    (event: React.MouseEvent<HTMLElement>, newRegions: Array<string> | string) => {
      const regionId = Array.isArray(newRegions) ? newRegions[0] : newRegions?.toString();
      const region = regions?.find((r) => r.id === regionId);
      if (region) setSelectedRegion(region);
    },
    [regions],
  );

  useEffect(() => {
    const modalShouldBeOpen = MODAL_OPEN_PATHS.includes(asPath.split('?')[0]);
    setModalOpen(modalShouldBeOpen);
  }, [asPath]);

  useEffect(() => {
    if (isForecasterContentOn && isSubregionForecastActive) {
      dispatch(
        fetchForecasterContent({
          cookies: undefined,
          days: hasCoreForecastPermissions ? 7 : NON_PREMIUM_FORECAST_DAYS_HOMEPAGE,
          subregionId: selectedRegion?.id,
        }),
      );
    }
  }, [
    dispatch,
    hasCoreForecastPermissions,
    isForecasterContentOn,
    isSubregionForecastActive,
    selectedRegion,
  ]);

  useMount(() => {
    trackEvent(PLAYED_WEBCAM, {
      category: 'Homepage Spot Preview Card',
      premiumCam: false,
      spotName: 'dummy',
      camName: 'dummy',
      camId: 'dummy',
      favorite: false,
      spotId: 'dummy',
      videoType: 'live',
    });

    if (isForecasterContentOn) dispatch(fetchFavoriteSubregions(undefined));
  });

  return (
    <PageContextProvider pageName="Home">
      <HomePageMeta />
      {isLimitedTimePremiumPlusEligible && (
        <LimitedTimeOfferBanner splitConfig={limitedTimePremiumPlusConfig} />
      )}
      {swellEvents?.length && !userType?.isAnonymous ? (
        <section className="sl-section-container" data-testid="homepage-swell-events-top">
          <SwellEventCardContainer events={swellEvents || []} onClickCard={onClickSwellEventCard} />
        </section>
      ) : null}
      <section className="sl-section-container">
        <ContentCard
          card={{ name: BRAZE_CONTENT_CARDS.HOMEPAGE_HEADER, extras: null }}
          className={styles.contentCardHeader}
          testId="banner-braze-card-homepage-header"
        />
      </section>
      <Box className={styles.hideOverflow}>
        <ForecastOutlookContextProvider
          hasCoreForecastPermissions={hasCoreForecastPermissions}
          surfHeightUnit={units?.surfHeight}
        >
          <ModalContextual open={modalOpen} returnHref={returnHref}>
            <PageContextProvider pageName="Favorites Modal">
              <Favorites surfHeightUnit={units?.surfHeight} />
            </PageContextProvider>
          </ModalContextual>
          {treatments?.[SL_WEB_REWIND_RECAPS] === 'on' && <RegionalRewindRecapsPoc />}
          {userType.isAnonymous ? (
            <AnonHero
              makeContextualHref={makeContextualHref}
              userCountryCode={userCountryCode}
              geoTarget={geotarget}
              colorSchemeIndex={colorSchemeIndex}
            />
          ) : (
            <>
              {isForecasterContentOn && (
                <>
                  <Box className="sl-section-container" component="header" my={3}>
                    <Typography
                      data-testid="greeting"
                      variant={isMaxWidthTablet ? 'headline' : 'title2'}
                      mb={2}
                    >
                      {greeting}
                    </Typography>
                    {!!favorites.length && (
                      <Stack direction="row" spacing={2}>
                        <div className={styles.regionFiltersWrapper}>
                          <FavoriteRegionFilters
                            exclusive
                            favoriteRegions={regions}
                            handleRegionSelected={handleRegionSelected}
                            selectedRegions={selectedRegion?.id}
                          />
                        </div>
                        <Box className={styles.regionalFiltersActions}>
                          <Link href={contextualHref} as="/favorites?type=live" shallow>
                            <Button
                              className={styles.viewAllButton}
                              disableRipple
                              disableFocusRipple
                              disableTouchRipple
                              size="small"
                              variant="secondary"
                            >
                              {viewAllLabel}
                            </Button>
                          </Link>
                        </Box>
                      </Stack>
                    )}
                    {isSubregionForecastActive && !isMinWidthTablet && (
                      <Box my={2}>
                        <SubregionForecastContentNow
                          elevation={0}
                          forecastContent={forecastContent}
                          isLoading={forecastContentLoading}
                          isTitleOnly
                        />
                      </Box>
                    )}
                  </Box>
                  <Box className={styles.favoritesCarouselSnap}>
                    <FavoritesCarouselSnap
                      favorites={filteredSubregionFavorites}
                      units={units as unknown as LocalUnits}
                    />
                  </Box>
                  {isSubregionForecastActive && (
                    <Box className="sl-section-container" mt={4}>
                      {!isMinWidthTablet && (
                        <SubregionForecastContentHighlights
                          elevation={0}
                          forecastContent={forecastContent}
                          isLoading={forecastContentLoading}
                        />
                      )}
                      {isMinWidthTablet && (
                        <SubregionForecastContentGrouped
                          elevation={0}
                          forecastContent={forecastContent}
                          isLoading={forecastContentLoading}
                        />
                      )}
                    </Box>
                  )}
                </>
              )}
              {!isForecasterContentOn && (
                <FavoritesCarousel
                  makeContextualHref={makeContextualHref}
                  modalOpen={modalOpen}
                  userCountryCode={userCountryCode}
                />
              )}
            </>
          )}
          <section
            className="sl-section-container"
            style={
              isForecasterContentOn && !favorites.length
                ? undefined
                : {
                    marginTop: '40px',
                  }
            }
          >
            <ContentCard
              card={{ name: BRAZE_CONTENT_CARDS.HOMEPAGE_OUTLOOK, extras: null }}
              className={styles.contentCardOutlook}
              testId="banner-braze-card-homepage-outlook"
            />
          </section>
          <FavoritesForecastOutlook
            favorites={forecastOutlookFavorites}
            favoritesError={!!popularSpotsError}
            isSubregionForecastActive={isSubregionForecastActive}
          />
        </ForecastOutlookContextProvider>
        {showAds && (
          <div className={styles.ad} data-testid="homepage-ad">
            <GoogleDFP
              adConfig={loadAdConfig(
                'homepage_feed_top',
                adTarget,
                entitlements,
                !userType.isAnonymous,
                null,
              )}
            />
          </div>
        )}
        {swellEvents?.length && userType?.isAnonymous ? (
          <EventsAndAlerts>
            <SwellEventCardContainer
              events={swellEvents || []}
              onClickCard={onClickSwellEventCard}
            />
          </EventsAndAlerts>
        ) : null}
        <EditorialCarousel geotarget={geotarget} />
        {showAds && (
          <div className={cx(styles.ad, styles.bottomAd)} data-testid="homepage-ad">
            <GoogleDFP
              adConfig={loadAdConfig(
                'homepage_feed_bottom',
                adTarget,
                entitlements,
                !userType.isAnonymous,
                null,
              )}
            />
          </div>
        )}
      </Box>
      {isForecasterContentOn && (
        <ModalContextual
          backgroundColor={theme.palette.secondary.main}
          open={!!subregionForecast}
          returnHref={returnHref}
          scrollContainerId="forecaster-content-modal"
        >
          <SubregionForecast scrollContainerId="forecaster-content-modal" isSubregionListVisible />
        </ModalContextual>
      )}
    </PageContextProvider>
  );
};

export default Homepage;
