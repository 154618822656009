import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { getUserFavorites } from '@surfline/web-common';

import { useAppSelector } from 'stores/hooks';
import type { UserFavorites } from 'types/userFavorites';

export type Region = { forecastStatus: 'active' | 'inactive'; id: string; name: string };

const useFavorites = ({
  allRegions = false,
}: {
  allRegions?: boolean;
}): { favorites: UserFavorites; regions: Array<Region> } => {
  const favorites = useAppSelector(getUserFavorites, shallowEqual) as UserFavorites;
  const regions: Array<Region> = useMemo(
    () =>
      favorites?.reduce<Array<Region>>((acc, fav) => {
        if (!acc.some((region) => region.id === fav.subregionId)) {
          acc.push({
            id: fav.subregionId,
            name: fav.subregionName,
            forecastStatus: fav?.forecastStatus ?? 'inactive',
          });
        }
        return acc;
      }, []),
    [favorites],
  );

  return {
    favorites,
    regions: allRegions
      ? [{ forecastStatus: 'inactive', id: 'all', name: 'All' }, ...regions]
      : regions,
  };
};

export default useFavorites;
