import React from 'react';

const PadlockIcon: React.FC<{ className?: string; locked?: boolean }> = ({
  className,
  locked = true,
}) =>
  locked ? (
    <svg
      className={className}
      fill="none"
      height="10"
      viewBox="0 0 8 10"
      width="8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_817_19879)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.7271 3.909H6.818C7.37029 3.909 7.818 4.35672 7.818 4.909V8.909C7.818 9.46129 7.37029 9.909 6.818 9.909H1.18164C0.629355 9.909 0.181641 9.46129 0.181641 8.909V4.909C0.181641 4.35672 0.629356 3.909 1.18164 3.909H1.27255V2.81809C1.27255 1.31186 2.49359 0.0908203 3.99982 0.0908203C5.50605 0.0908203 6.7271 1.31186 6.7271 2.81809V3.909ZM5.63619 3.909V2.81809C5.63619 1.91435 4.90356 1.18173 3.99982 1.18173C3.09608 1.18173 2.36346 1.91435 2.36346 2.81809V3.909H5.63619Z"
          fill="#424242"
        />
      </g>
      <defs>
        <clipPath id="clip0_817_19879">
          <rect width="8" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6.22222V6.23H10H9H8V6.22222H7H6.38048H3.42857V4.44444C3.42857 2.97169 4.57984 1.77778 6 1.77778C6.90686 1.77778 7.70407 2.26461 8.16189 3H9H10H10.0543C9.47616 1.25447 7.87934 0 6 0C3.63307 0 1.71429 1.98985 1.71429 4.44444V6.22222H0.857143C0.383756 6.22222 0 6.62019 0 7.11111V15.1111C0 15.602 0.383756 16 0.857143 16H11.1429C11.6162 16 12 15.602 12 15.1111V7.11111C12 6.62019 11.6162 6.22222 11.1429 6.22222H11Z"
        fill="#76E694"
      />
    </svg>
  );

export default PadlockIcon;
