import getConfig from 'next/config';
import { nrNoticeError } from '@surfline/web-common';

const CONTENTFUL_CONTENT_API_URL = `https://graphql.contentful.com/content/v1/spaces/${
  process.env.CONTENTFUL_SPACE_KEY || getConfig()?.publicRuntimeConfig?.CONTENTFUL_SPACE_KEY
}`;

const checkStatus = (res: Response) => {
  if (res.status >= 200 && res.status < 300) {
    return res;
  }

  const error: any = new Error(res.statusText);
  error.response = res;
  error.statusCode = res.status;

  if (error.statusCode >= 500) {
    nrNoticeError(error, {});
  }

  throw error;
};

const parseJSON = (response: Response) => response.json();

const contentfulFetch = async (query: BodyInit | null | undefined) =>
  fetch(CONTENTFUL_CONTENT_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${
        process.env.CONTENTFUL_CONTENT_DELIVERY_TOKEN ||
        getConfig()?.publicRuntimeConfig?.CONTENTFUL_CONTENT_DELIVERY_TOKEN
      }`,
    },
    body: query,
  })
    .then(checkStatus)
    .then(parseJSON);

export default contentfulFetch;
