import { useMemo } from 'react';
import { useAppSelector } from '../stores/hooks';

export const getPrimarySpotId = (state) => state.subregion.primarySpotId;
export const getSubregionFeed = (state) => state.subregion.feed;
export const getSubregionId = (state) => state.subregion.subregionId;

export const getSubregionAssociated = (state) => {
  const overview = state?.subregion?.overview;
  if (overview) return overview.associated;
  return null;
};

export const getSubregionOverviewLoading = (state) => state.subregion.overview.loading;

export const getSubregionOverviewData = (state) =>
  state?.subregion?.overview?.overview?.data ?? null;

export const getFavoriteSubregions = (state) => state?.subregion?.favorites?.data ?? null;

export const getNearbySubregions = (state) => state?.subregion?.nearby?.data ?? null;

export const getForecastContent = (state) => state?.subregion?.forecast?.data ?? null;

export const getForecastContentLoading = (state) => state?.subregion?.forecast?.loading;

/**
 * @param {import('../stores').AppState} state
 */
export const getSubregionLoading = (state) => state.subregion.loading;

export const useSubregionForecastArticles = () => {
  const allArticles = useAppSelector(getSubregionFeed);
  const forecastArticles = useMemo(
    () => allArticles?.articles?.filter((article) => article.contentType === 'FORECAST') ?? [],
    [allArticles],
  );
  return forecastArticles;
};
