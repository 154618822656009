import IconButton from '@mui/material/IconButton';

import VideoPlaylistPlayer from 'components/VideoPlaylistPlayer';
import classNames from 'classnames';
import { ChevronLeft, ChevronRight } from 'components/Icons';
import Typography from '@mui/material/Typography';
import { formatInTimeZone } from 'date-fns-tz';

import theme from '@wavetrak/theme';
import styles from './ReelPlayer.module.scss';
import ConditionsBar from '../ConditionsBar/ConditionsBar';

// TODO - populate this
type Props = any;

const ReelPlayer = ({
  units,
  clips,
  currentClipIndex,
  setCurrentClipIndex,
  setCurrentClip,
}: Props) => (
  <div className={styles.playerContainer}>
    <IconButton
      disableRipple
      className={styles.skipButton}
      onClick={() => {
        setCurrentClipIndex(currentClipIndex - 1);
      }}
      disabled={currentClipIndex <= 0}
    >
      <ChevronLeft />
    </IconButton>
    <div className={styles.playerOverlayContainer}>
      <Typography variant="caption1" color={theme.palette.common.white} className={styles.time}>
        {clips[currentClipIndex]?.startTime &&
          `${formatInTimeZone(
            clips[currentClipIndex].startTime,
            clips[currentClipIndex].timezone,
            "hh:mmaaaaa'm'",
          )}
              ${clips[currentClipIndex]?.label || ''}`}
      </Typography>
      <div className={styles.clipIndicatorContainer}>
        {clips.map((clip: any, index: number) => (
          <div
            key={clip.startTime}
            className={classNames(
              styles.clipIndicator,
              currentClipIndex >= index && styles.activeClip,
            )}
          />
        ))}
      </div>
      <VideoPlaylistPlayer
        playlist={clips.map((clip: any) => clip.url) as string[]}
        currentPlayer={currentClipIndex}
        setCurrentPlayer={setCurrentClip}
      />
      {clips && (
        <ConditionsBar
          units={units}
          surfHeight={clips[currentClipIndex]?.surfHeight}
          rating={clips[currentClipIndex]?.rating}
        />
      )}
    </div>
    <IconButton
      disableRipple
      className={styles.skipButton}
      onClick={() => {
        setCurrentClipIndex(currentClipIndex + 1);
      }}
      disabled={currentClipIndex >= clips.length - 1}
    >
      <ChevronRight />
    </IconButton>
  </div>
);

export default ReelPlayer;
